import { getAnalysisStatistics } from '@api';
import { queryKeys } from '@app/react-query/constans';
import { useQuery } from '@tanstack/react-query';

export function useAverageAnalysisQuery() {
  const fallbackData = {};
  const {
    data = fallbackData,
    isLoading,
    isError,
  } = useQuery(queryKeys.analysis(), () => getAnalysisStatistics());

  return { data, isLoading, isError };
}
