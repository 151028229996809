import {
    PURGE_SCHOOL,

    GET_SCHOOL_SUCCESS,
    GET_SCHOOL_FAIL,

    GET_SCHOOL_BY_OPTION_SUCCESS,
    GET_SCHOOL_BY_OPTION_FAIL,

  } from './actionTypes';
  import * as api from '@api';
  import { createPromiseAction } from '@adobe/redux-saga-promise';

  export const purgeSchool = () => ({
    type: PURGE_SCHOOL,
  });
  
  ///// GET_SCHOOL
  export const getSchoolPromise = createPromiseAction("GET_SCHOOL_PROMISE");
  
  export const getSchoolSuccess = schoolData => ({
    type: GET_SCHOOL_SUCCESS,
    school: schoolData,
  })
  
  export const getSchoolFail = error => ({
    type: GET_SCHOOL_FAIL,
    payload: error,
  });

  // GET_SCHOOL_BY_OPTION
  export const getSchoolByOptionPromise = createPromiseAction(
    "GET_SCHOOL_BY_OPTION_PROMISE"
  );
  
  export const getSchoolByOptionSuccess = (allSchoolData) => {
    const allSchool = allSchoolData.schools;
    const schoolName = allSchool.map((schoolData) => schoolData.name);
    return {
      type: GET_SCHOOL_BY_OPTION_SUCCESS,
      schoolName: schoolName,
    };
  };
  
  export const getSchoolByOptionFail = (error) => ({
    type: GET_SCHOOL_BY_OPTION_FAIL,
    error: error,
  });