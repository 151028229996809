import React from 'react';
import {
  Box,
  Heading,
  Link,
  Image,
  Text,
  HStack,
  Tag,
  useColorModeValue,
  Container,
} from '@chakra-ui/react';
import {
  paragraphIndent,
  paragraphLineHeight,
  paragraphSpacing,
} from '@app/assets/theme/typoStyle';
import SummaryText from './SummeryText';
import GeneratedCommentsText from './GeneratedComments';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const BlogTags = (props) => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map((tag) => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

export const BlogAuthor = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src="https://100k-faces.glitch.me/random-image"
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

const Comments = (props) => {
  const { studentData, commentsData, splitText } = props;

  return (
    <Container maxW={'7xl'} p="8" mb={'6'}>
      {/* <Heading as="h1" fontFamily={'Noto Sans KR'}>
        닥터 사이먼의 학습과 마음
      </Heading>
      <Divider marginTop="5" /> */}
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}
        >
          {/* <BlogTags tags={['태그 샘플', '태그추가하기']} /> */}
          <Heading marginTop="1">
            <Link
              textDecoration="none"
              _hover={{ textDecoration: 'none' }}
              fontFamily={'Noto Serif KR'}
            >
              {studentData.studentName}의 마음과 공부에 관한 분석
            </Link>
          </Heading>
          {/* <SummaryText
            commentsData={commentsData}
            paragraphIndent={paragraphIndent}
            paragraphLineHeight={paragraphLineHeight}
          /> */}
          {splitText.map((item) => {
            return (
              <GeneratedCommentsText
                key={item.id}
                content={item.content}
                paragraphIndent={paragraphIndent}
                paragraphLineHeight={paragraphLineHeight}
              />
            );
          })}
          <Box bg={'gray.100'} p={4} mt={4} borderRadius={8} marginTop={'6'}>
            <Text fontSize="lg" fontFamily={'Noto Serif KR'}>
              닥터 사이먼이 준비한 리포트는 어떠셨나요?
              <br /> 더 나은 서비스를 위해 익명으로 설문조사를 진행하고 있으며,
              닥터 사이먼의 자문위원이 되실 학부모님도 모집하고 있습니다.
              <br /> 아래 버튼을 눌러 설문에 참여해 주세요. 감사합니다.
            </Text>
            <Box
              mt={'6'}
              bg={'gray.300'}
              p={4}
              width={'fit-content'}
              borderRadius={8}
              // onMouseOver={(e) => {
              //   e.target.style.backgroundColor = '#F6E05E';
              // }}
              // onMouseLeave={(e) => {
              //   e.target.style.backgroundColor = '#CBD5E0';
              // }}
            >
              <Link
                fontSize="lg"
                fontFamily={'Noto Serif KR'}
                href="https://forms.gle/4EK7Ne9QESyv7Mye7"
                isExternal
                my={'4'}
              >
                설문조사 바로가기
                <ExternalLinkIcon mx="2px" />
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Comments;
