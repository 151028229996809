import React, { useState } from 'react';
import styles from './CardManagerInfo.module.scss';
import PersonIcon from '@mui/icons-material/Person';
import InfoUpdateModal from '@components/Modal/InfoUpdateModal';
import { useDispatch, useSelector } from "react-redux";
import {
    deleteClientAdminPromise,
    getAllManagerTeachersAdminPromise,
    stopClientAdminPromise,
    reuseClientAdminPromise,
    updateTeacherInfoAdminPromise,
} from '@store/actions';
import { Tooltip } from '@mui/material';
import ReactGA from 'react-ga';

const CardManagerInfo = ({ username, email, user_id, school_id, user_state }) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [userStateText, setUserStateText] = useState(user_state === "REGISTERED_APPROVED" ? "일시 정지하기" : "정지 해제하기");

    const onStateClick = () => {
        if (user_state === "REGISTERED_APPROVED") {
            if (window.confirm("해당 계정을 정지시키겠습니까?")) {
                onState();
            } else {
                console.log("취소");
            }
            return;
        } 
        if (window.confirm("해당 계정을 활성시키겠습니까?")) {
            onState();
        } else {
            console.log("취소");
        }
    };

    const onState = () => {
        if (user_state === "REGISTERED_APPROVED") {//활성 상태일떄
            dispatch(stopClientAdminPromise({ accountId: user_id, schoolId:school_id, state:'REGISTERED_CERT'})).then((result) => {
                console.log("상태 stop 됐는지 확인", result);
                setUserStateText(result.state === "REGISTERED_APPROVED" ? "일시 정지하기" : "정지 해제하기");
                dispatch(getAllManagerTeachersAdminPromise(school_id))
                    .then((teachersData) => {
                        if (teachersData === undefined) {
                            throw new Error('already signed up!');
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            });
        } else {//정지 상태일때
            dispatch(reuseClientAdminPromise({ accountId: user_id, schoolId:school_id, state:'REGISTERED_APPROVED'})).then((result) => {
                console.log("상태 다시 활성화 됐는지 확인", result);
                setUserStateText(result.state === "REGISTERED_APPROVED" ? "일시 정지하기" : "정지 해제하기");
                dispatch(getAllManagerTeachersAdminPromise(school_id))
                    .then((teachersData) => {
                        if (teachersData === undefined) {
                            throw new Error('already signed up!');
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            });
        }

    };

    const onDelete = () => {
        if (window.confirm("해당 계정을 관리자에서 삭제하시겠습니까?")) {
            navigateToDeleteTeacher();
        } else {
            console.log("취소");
        }
    };

    const navigateToDeleteTeacher = () => {
        dispatch(
            updateTeacherInfoAdminPromise({
              accountId: user_id,
              role: "TEACHER",
            })
          ).then((userInfo) => {
              if (userInfo === undefined) {
                throw new Error("login failed");
              }
              console.log("updateUserInfoPromise user info : ", userInfo);
              dispatch(getAllManagerTeachersAdminPromise(userInfo.schoolId))
                .then((teachersData) => {
                  if (teachersData === undefined) {
                    throw new Error('already signed up!');
                  }
                })
                .catch((error) => {
                  console.error(error);
                });
            })
            .catch((error) => {
              console.log("userUpdate error", error);
            });
    };

    return (
        <>
            <div className={styles['main-container']}>
                <div className={styles['main-container-userName-box']}>
                    <div className={styles['main-container-img']}>
                        <PersonIcon sx={{ fontSize: 50 }} />
                    </div>
                    <div className={styles['main-container-userName']}>
                        <div>{username}</div>
                    </div>
                </div>
                <div className={styles['email-container']}>{email}</div>
                <div className={styles['main-container-accountState']}>
                    <div className={user_state === "REGISTERED_APPROVED" ? styles['account-state'] : styles['account-state2']}>
                        {user_state === "REGISTERED_APPROVED" ? '사용 가능' : '사용 정지중'}
                    </div>
                </div>
                <div className={styles['button-container']}>
                    <Tooltip title='이름, 이메일, 비밀번호 수정이 가능합니다' arrow>
                        <button
                            className={styles['update-button']}
                            onClick={() => { setShow(true); }}
                        >
                            수정
                        </button>
                    </Tooltip>
                    <Tooltip title={user_state === "REGISTERED_APPROVED" ? '클릭 시 계정 사용이 정지되도록 변경 가능합니다' : '클릭 시 사용 가능한 계정으로 변경 가능합니다'} arrow>
                        <button
                            onClick={onStateClick}
                            className={user_state === "REGISTERED_APPROVED" ? styles['state-button'] : styles['state-button2']}
                        >{userStateText}</button>
                    </Tooltip>
                    <Tooltip title='계정 삭제 시 관리자용으로 계정 사용은 불가하지만 선생님용으로는 사용 가능합니다' arrow>
                        <button
                            onClick={() => {
                                onDelete();
                                ReactGA.event({
                                    action: "Click Delete Admin Teacher Account",
                                    category: "Button",
                                    label: "Manager",
                                  });
                            }}
                            className={styles['delete-button']}
                        >계정 삭제</button>
                    </Tooltip>
                </div>
            </div>
            <InfoUpdateModal show={show} setShow={setShow} propUsername={username} propEmail={email} propUser_id={user_id} />
        </>
    );
};

export default CardManagerInfo;
