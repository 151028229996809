export const PURGE_ACCOUNT = 'PURGE_ACCOUNT';

export const REGISTER_TEACHER = 'REGISTER_TEACHER';
export const REGISTER_TEACHER_SUCCESS = 'REGISTER_TEACHER_SUCCESS';
export const REGISTER_TEACHER_FAIL = 'REGISTER_TEACHER_FAIL';

export const GET_ACCOUNT_INFO_BY_ACCOUNTID_SUCCESS =
  'GET_ACCOUNT_INFO_BY_ACCOUNTID_SUCCESS';
export const GET_ACCOUNT_INFO_BY_ACCOUNTID_FAIL =
  'GET_ACCOUNT_INFO_BY_ACCOUNTID_SUCCESS';
export const GET_ACCOUNT_INFO_BY_CLIENTID_SUCCESS =
  'GET_ACCOUNT_INFO_BY_CLIENTID';
export const GET_ACCOUNT_INFO_BY_CLIENTID = 'GET_ACCOUNT_INFO_BY_CLIENTID';
