import React from 'react';
import { Box, Container } from '@chakra-ui/react';
import ReactApexChart from 'react-apexcharts';

class LineChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: '개념이해',
          type: 'area',
          data: [100, 91, 86, 79, 72, 64, 59, 47, 37, 23],
        },
        {
          name: '성취점수',
          type: 'line',
          data: [92.7, 80.6, 72.6, 65.8, 58.5, 52.4, 45.8, 37.3, 27.5, 15.4],
        },
        {
          name: '충동성향',
          type: 'line',
          data: [34, 35, 47, 38, 53, 43, 54, 68, 69, 86],
        },
        {
          name: '충동성향 추세선',
          type: 'line',
          data: [30, 35, 40, 45, 50, 55, 60, 65, 70, 75], //추세선 수치는 정확하지 않음
          // tooltip: {
          //   enabled: true,
          // },
        },
        {
          name: '학습태도',
          type: 'line',
          data: [92.7, 93.6, 85.2, 87.2, 84.3, 76.6, 77.3, 69.4, 68.3, 66.9],
        },
        {
          name: '학습태도 추세선',
          type: 'line',
          data: [93, 90, 87, 85, 82, 79, 76, 73, 70, 67],
          // tooltip: {
          //   enabled: true,
          // },
        },
        {
          name: '띄어쓰기',
          type: 'line',
          data: [100, 85, 87, 84, 76, 77, 71, 61, 63, 46],
        },
        {
          name: '띄어쓰기 추세선',
          type: 'line',
          data: [95, 91, 87, 83, 78, 73, 67, 63, 59, 55],
          // tooltip: {
          //   enabled: true,
          // },
        },
      ],
      options: {
        chart: {
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        colors: [
          '#2EC5A2',
          '#2EC5A2',
          '#BD4D6F',
          '#F16590', //충동성향 추세선
          '#3689BD',
          '#49B2F4', //학습태도 추세선
          '#D18E33',
          '#FFAE42', //띄어쓰기 추세선
        ],
        dataLabels: {
          // enabledOnSeries: [1],
          enabledOnSeries: [],
        },
        stroke: {
          curve: ['straight', 'straight', 'straight', 'straight', 'straight', 'straight', 'straight', 'straight'],
          width: [0, 2, 2, 2, 2, 2, 2, 2],
          dashArray: [0, 0, 5, 0, 5, 0, 5, 0],
        },
        fill: {
          // 0번째를 제외하고 나머진 solid
          type: ['solid', 'solid', 'solid', 'solid', 'solid', 'solid', 'solid', 'solid', 'solid', 'solid'],
          opacity: [0.1, 99, 0.5, 99, 0.5, 99, 0.5, 99, 99, 99],
        },
        // markers: {
        //   size: 5, 
        //   strokeWidth: 0, 
        //   colors: ['#48BB78'], 
        //   hover: {
        //     size: 7 
        //   }
        // },
        // tooltip: {
        //     enabled: true,
        // },

        xaxis: {
          categories: [
            '1등급',
            '2등급',
            '3등급',
            '4등급',
            '5등급',
            '6등급',
            '7등급',
            '8등급',
            '9등급',
            '10등급',
          ],
          // tooltip: {
          //     enabled: true,
          // },
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              return value + "점";
            }
          },
          title: {
            // text: '성취점수',
          },
          min: 0,
          max: 100,
          tickAmount: 5,
          style: {
            fontSize: '12px',
            fontFamily: 'Noto Sans KR',
            fontWeight: 400,
            cssClass: 'apexcharts-yaxis-title',
          },
        },
        // title: {
        //   text: '충동성',
        //   align: 'center',
        //   // 크기 조정
        //   style: {
        //     fontSize: '16px',
        //     color: '#48BB78',
        //     fontFamily: 'Noto Sans KR',
        //   },
        // },
      },
    };
  }

  render() {
    return (
      <Container maxW={'7xl'} p="12">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="area"
          height={350}
        />
      </Container>
    );
  }
}

export default LineChart;
