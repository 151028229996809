import {
  PURGE_CONTROL,

  SET_APPROVAL_COMPLETED,
  SET_SIDEBAR_STATE,
} from './actionTypes';

const initialState = {
  dirty: 0,

  approvalCompleted: false,
  sidebarState: false,
};

const controls = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_CONTROL:
      return initialState;

    case SET_APPROVAL_COMPLETED:
      return { ...state, approvalCompleted: true };
    case SET_SIDEBAR_STATE:
      return { ...state, sidebarState: true };
    default:
      break;
  }
  return state;
};

export default controls;
