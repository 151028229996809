import React from 'react';
import {
  Box,
  Heading,
  Link,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  useColorModeValue,
  Container,
  VStack,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import Image1 from '@app/assets/images/pagethree_1.png';
import Image2 from '@app/assets/images/chart1.png';
import Image3 from '@app/assets/images/chart2.png';
import Image4 from '@app/assets/images/chart3.png';
import Image5 from '@app/assets/images/pagethree_5.png';
import Image6 from '@app/assets/images/pagethree_6.png';
import Image7 from '@app/assets/images/pagethree_7.png';
import {
  paragraphIndent,
  paragraphLineHeight,
  paragraphSpacing,
  WIDTHS2,
} from '@app/assets/theme/typoStyle';

const BlogTags = (props) => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map((tag) => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

export const BlogAuthor = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src="https://100k-faces.glitch.me/random-image"
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

const BlogPageThree = () => {
  return (
    <Container maxW={'7xl'} p="8">
      {/* <Heading as="h1" fontFamily={'Noto Sans KR'}>
                닥터 사이먼의 학습과 마음
            </Heading>
            <Divider marginTop="5" /> */}
      {/* box1: 성취점수 */}
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between"
      >
        {/* <Box
                    display="flex"
                    flex="1"
                    marginRight="3"
                    position="relative"
                    // alignItems="center"
                >
                    <Box
                        width={{ base: '100%', sm: '85%' }}
                        zIndex="2"
                        marginLeft={{ base: '0', sm: '5%' }}
                        marginTop="5%"
                    >
                        <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
                            <Image
                                borderRadius="lg"
                                src={Image2}
                                alt="닥터 사이먼"
                                objectFit="contain"
                            />
                        </Link>
                    </Box>
                    <Box zIndex="1" width="100%" position="absolute" height="100%">
                        <Box backgroundSize="20px 20px" opacity="0.4" height="100%" />
                    </Box>
                </Box> */}
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}
        >
          {/* <BlogTags tags={['태그 샘플', '태그추가하기']} /> */}
          <Heading marginTop="1">
            <Link textDecoration="none" _hover={{ textDecoration: 'none' }} fontFamily={'Noto Serif KR'}>
              3. 닥터사이먼의 빅데이터 분석: 충동적인 마음과 학습의 관계
            </Link>
          </Heading>
          <Link textDecoration="none" _hover={{ textDecoration: 'none' }} display={'flex'} justifyContent={'center'}>
            <Image
              borderRadius="lg"
              src={Image2}
              alt="닥터 사이먼"
              objectFit="contain"
              marginTop="6"
              width={WIDTHS2}
            />
          </Link>
          <Text
            as="p"
            marginTop="6"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
          >
            자, 이제 닥터 사이먼의 빅데이터 분석 결과를 보시지요. 이번
            분석보고서는 먼저 국어와 수학과목을 합친 성적을 중심으로 보겠습니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            위 그래프에서 X축은 학생들의 성적을 1등급에서 10등급으로
            나타냈습니다. 1등급은 성적 상위 10%, 그리고 10등급은 하위 10%에
            해당합니다. Y축 100점 만점 기준의 성취 점수입니다. 이 그래프에서{' '}
            <Text fontWeight={'bold'} color={'green.500'} display={'inline'}>
              녹색 바탕색{' '}
            </Text>
            은 개념 이해 정도를 나타내고,{' '}
            <Text fontWeight={'bold'} color={'green.500'} display={'inline'}>
              굵은 녹색 선
            </Text>
            은 문제를 맞힌 점수입니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            그래프에서 보시는 것처럼,{' '}
            <Text fontWeight={'bold'} display={'inline'}>
              개념 이해 수준이 성취점수보다 살짝 높습니다. 정상적인 패턴입니다.
              성적보다 이해가 먼저라는 얘기죠.
            </Text>{' '}
            보통 이해수준이 성적보다 높습니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            그런데{' '}
            <Text fontWeight={'bold'} display={'inline'}>
              만약 자녀의 성적이 개념 이해 수준보다 지나치게 높게 나온다면,
            </Text>{' '}
            그리고 그 현상이 지속적이라면 정상적인 패턴이 아닙니다. 내용은
            알지도 못하면서 답만 맞춘 경우입니다. 아니면 정답신공을 가지고
            있거나 부정행위 가능성이 있겠지요. 만약 제대로 된 시험을 보게 되면,
            성적이 떨어질 것으로 예측할 수 있습니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            이와 달리,{' '}
            <Text fontWeight={'bold'} display={'inline'}>
              개념이해 수준은 높은데 성적이 나오지 않는다면,
            </Text>{' '}
            그 원인을 자세히 분석할 필요가 있습니다. 실수를 용납하지 않는 수학
            과목이라면, 특히 충동성이 문제인지 살펴야 합니다. 충동성이 낮고 학습
            태도가 좋다면, 아이가 사소한 실수를 반복하는 것은 아닌지, 뭔가 채점
            기준에 부족한 스킬이 있거나, 반복적으로 나타나는 어려움이 있는지,
            시험 공포가 있는지 등등 원인을 분석해서 줄이려는 노력이 필요합니다.
            수학이나 과학 과목과 달리, 국어나 사회 과목에서는 원인이 달라질 수
            있습니다.
          </Text>
        </Box>
      </Box>
      {/* box2: 3-1 충동성 */}
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between"
      >
        {/* <Box
                    display="flex"
                    flex="1"
                    marginRight="3"
                    position="relative"
                    // alignItems="center"
                >
                    <Box
                        width={{ base: '100%', sm: '85%' }}
                        zIndex="2"
                        marginLeft={{ base: '0', sm: '5%' }}
                        marginTop="5%"
                    >
                        <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
                            <Image
                                borderRadius="lg"
                                src={Image3}
                                alt="닥터 사이먼"
                                objectFit="contain"
                            />
                        </Link>
                    </Box>
                    <Box zIndex="1" width="100%" position="absolute" height="100%">
                        <Box backgroundSize="20px 20px" opacity="0.4" height="100%" />
                    </Box>
                </Box> */}
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}
        >
          <Heading as={'h2'} size={'lg'} marginTop="2">
            3-1. 충동적인 아이는 성적이 나쁘다.
          </Heading>
          <Link textDecoration="none" _hover={{ textDecoration: 'none' }} display={'flex'} justifyContent={'center'}>
            <Image
              borderRadius="lg"
              src={Image3}
              alt="닥터 사이먼"
              objectFit="contain"
              marginTop="6"
              width={WIDTHS2}
            />
          </Link>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
          >
            앞에 있던 그래프에 붉은색 선을 더했습니다.{' '}
            <Text fontWeight={'bold'} display={'inline'} color={'red.300'}>
              이 붉은색 선이 충동성
            </Text>
            인데, 이해하기 쉽도록 T방식으로 환산한 점수입니다. 보시는 것처럼,
            1등급 학생들의 충동점수가 제일 낮고, 등급이 높아질수록 충동성이
            높아집니다. 일반적으로,{' '}
            <Text fontWeight={'bold'} display={'inline'}>
              충동성이 낮을수록 성적이 높고, 충동성이 높을수록 성적이 낮다
            </Text>
            는 관계를 파악하실 수 있습니다. 그래프에는 없지만, 충동성의 경계선을
            넘은 충동적인 학생 수를 보면, 상위 1등급 대비 하위 10등급에서
            충동적인 학생이 5배나 더 많습니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            학교에선 충동적인 욕구를 참아내며 정확하게 답하는 것을 강조하고,
            행동하기 전에 생각하는 것을 미덕으로 봅니다. 충동성향이 강한
            아이들은 특히 과학, 기술, 수학 같은 소위 스탬(STEM) 과목에 취약하기
            쉽습니다. 이런 과목들은 대개 정확한 개념이해와 오류를 줄여야 하는
            과목입니다. 국어 역시 논리와 이해, 암기 중심의 지식 평가가 핵심일
            경우, 충동성은 성적에 나쁜 영향을 미칩니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            이 때문에{' '}
            <Text fontWeight={'bold'} display={'inline'}>
              개념 이해는 잘 하는데, 성적이 안나온다면 반복학습을 해서 숙달할
              필요가 있습니다.
            </Text>
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            <Text fontWeight={'bold'} display={'inline'}>
              그러면 선행학습은 효과가 없을까요?
            </Text>{' '}
            물론 선행학습(prior learning)과 반복 학습(repetitive learning)은
            병행하는 게 당연히 좋지만, 수학이나 과학과 달리 복잡한 사회
            과목에서는 선행학습이 보다 효과적이라는 연구결과들이 있습니다.
            사용하는 용어에서부터 개념이 모두 수학이나 과학처럼 엄밀하기보단
            관계에 따라 달라지는 상대적인 의미를 가지는 경우, 미리 전체적인 개념
            관계를 섭렵하는 선행 학습이 더욱 효과적일 수 있습니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            페라리(Ferrari, 1995)에 의하면, 충동적인 아이는 해야 할 공부가 아닌
            게임 같은 주위 자극이나 다른 활동에 쉽게 마음을 뺏기기 때문에,
            과제를 줬을 때 곧바로 시작하지 못한다고 말합니다. 이렇게 주의집중이
            어려운 나머지, 학습 동기를 유지하지 못하는 것이 충동성의 특징입니다.
            참고로, 우울감을 호소하는 아이도 느리게 시작하는 경향이 있으니, 잘
            구별해야 합니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            특히, 우리{' '}
            <Text fontWeight={'bold'} display={'inline'}>
              아이는 열심히 하는데, 성적이 오르지 않는다고 걱정하는 경우에
              충동적인 성향의 학생들이 관찰되곤 합니다.
            </Text>{' '}
            특히 정확히 이해하지 않으면서 진도만 나가는 상황이 대표적입니다.
            아이만 그런게 아니라 사설 학원의 선행 학습도 비슷한 경향이 있습니다.
            초등학생이 수학 정석을 한다는 식으로 포장하는 것이지요.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            그런데 심지어 선생님 말씀을 경청하고, 노트필기도 열심히 하는 데도
            성적이 오르지 않는 경우도 있습니다. 이를 마음이 주의집중해야 하는
            영역(zone)에서 벗어난다는 “zoning out” 현상이라고 말합니다. 소위
            멍때리기나 유체이탈라고 할 수 있습니다. 예를 들어, 미팅에서 다른
            사람이 얘기하면 듣고 있는 듯하지만 자동적으로 들리지 않는
            현상입니다. 고개를 끄덕였는데, 기계가 된 듯 아무 기억이 나지 않는
            현상입니다. 선생님과 함께 책을 읽고 나도 아무 기억이 나지 않는 것도
            같은 맥락입니다. 즉, 들어도(hearing) 듣는 게(listening) 아니고,
            본다고(seeing)고 보는 게(watching) 아닙니다. 오직 눈이건 귀건
            상관없이, 마음이 주의집중 할 때만 실제로 보고 듣는 것이지요.
            조닝아웃은 무서운 반학습 일탈 기술입니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            그런데{' '}
            <Text fontWeight={'bold'} display={'inline'}>
              충동성이 너무 낮아도 비슷한 상황이 일어날 수
            </Text>{' '}
            있습니다. 모험심이 전혀 없어서 하던 것만 하거나, 할 수 있는 것만
            계속하는 것이죠. 난도를 높여서 어려운 문제를 풀려고 하지 않는
            경우입니다. 이런 학생의 경우 혼자 하는 것보다 이를 파악할 수 있는
            부모의 세심한 관찰과 격려가 필요합니다.
          </Text>
        </Box>
      </Box>
      {/* box3: 3-2 학습태도 */}
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between"
      >
        {/* <Box
                    display="flex"
                    flex="1"
                    marginRight="3"
                    position="relative"
                    // alignItems="center"
                >
                    <Box
                        width={{ base: '100%', sm: '85%' }}
                        zIndex="2"
                        marginLeft={{ base: '0', sm: '5%' }}
                        marginTop="5%"
                    >
                        <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
                            <Image
                                borderRadius="lg"
                                src={Image4}
                                alt="닥터 사이먼"
                                objectFit="contain"
                            />
                        </Link>
                    </Box>
                    <Box zIndex="1" width="100%" position="absolute" height="100%">
                        <Box backgroundSize="20px 20px" opacity="0.4" height="100%" />
                    </Box>
                </Box> */}
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}
        >
          <Heading as={'h2'} size={'lg'} marginTop="2">
            3-2. 충동성이 강하면, 학습태도가 나쁘다.
          </Heading>
          <Link textDecoration="none" _hover={{ textDecoration: 'none' }} display={'flex'} justifyContent={'center'}>
            <Image
              borderRadius="lg"
              src={Image4}
              alt="닥터 사이먼"
              objectFit="contain"
              marginTop="6"
              width={WIDTHS2}
            />
          </Link>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            fontWeight={'bold'}
            textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            이번 그래프에 추가된{' '}
            <Text color={'#528BE9'} display={'inline'}>
              파란 선이 학습 태도
            </Text>
            입니다. 학습 태도란 주어진 공부에 최선을 다하려는 마음가짐입니다.
            학습 태도는 수업이나 과제, 시험 등을 학생이 인식하는 자세이기 때문에
            학습 태도를 어떻게 발달시키느냐는 결국 아이가 공부에 임하는 자세를
            결정하게 됩니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            보시는 것처럼,{' '}
            <Text fontWeight={'bold'} display={'inline'}>
              충동성이 높을수록 학습상황을 부정적으로 바라보고 있습니다.
              성적이나 이해가 낮을수록 학습태도가 낮습니다.
            </Text>
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
          >
            학습태도를 매리어트(Marriott) 박사 등에 의하면 학생이 노력으로
            극복할 수 있다는 태도를 가지면, 충동성때문에 실패한 학업을 개선할 수
            있다는 연구결과를 발표하였습니다. 학습을 위한 개방적 태도가
            중요합니다. 처음에는 복잡하고 중요해 보여도, 배울 수 있다는 믿음을
            가지는 것이 중요합니다. 그리고 아무리 복잡해 보이는 큰 문제도, 작은
            문제로 작게 쪼개는 분할정복(divide and conquer) 방법을 연습하면
            도움이 됩니다.
          </Text>
          <Link
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6711531/"
            isExternal
            my={'4'}
            marginBottom="6"
          >
            참고 사이트
            <ExternalLinkIcon mx="2px" />
          </Link>
        </Box>
      </Box>
    </Container>
  );
};

export default BlogPageThree;
