import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getStorageData, loginHistory } from '@api';
import { signInByEmailPromise } from '@app/store/actions';
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useFormik } from 'formik';

const SignIn = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [loginBtnText, setLoginBtnText] = useState('로그인');
  const [isSignup, setIsSignup] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [roleSelect, setRoleSelect] = useState('register');

  // todo: ip 주소 가져오기
  // const getIPAddress = async () => {
  //   const response = await fetch('https://api.ipify.org/?format=json');
  //   const data = await response.json();
  //   return data.ip;
  // }
  
  // getIPAddress().then(ip => console.log(ip));

  const loginText = '로그인';
  const signupText = '사용신청';

  useEffect(() => {
    getStorageData('@user').then((user_raw) => {
      const user = JSON.parse(user_raw);
      // console.log("user : ", user);
      if (user !== null) {
        setLoginBtnText(loginText);
        setIsSignup(false);
      } else {
        setLoginBtnText(loginText);
        setIsSignup(true);
      }
    });
  }, []);

  useEffect(() => {
    if (isSignup) {
      setLoginBtnText(loginText);
    } else {
      setLoginBtnText(loginText);
    }
  }, [isSignup]);

  const isEmailvalid = (email) => {
    const regex = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    if (!email || regex.test(email) === false) {
      console.log('entered email: ', email);
      alert('이메일을 입력해주세요!');

      return false;
    }
    return true;
  };

  useEffect(() => {
    if (user.state === 'REGISTERED_CERT') {
      console.log('RESULT OF USEEFFECT', user);
      // redirect to auth manager
      //history.replace("/home");
      //history.replace("/auth-manager");
      // window.location.href = "/auth-manager";
      // alert("_CERT USER");
      throw new Error('login failed _Cert');
      return;
    } else if (
      // user.state === "REGISTERED_APPROVED" ||
      // user.state === "REGISTERED_CERT" ||
      // user.state === "REGISTERED_PENDING" ||
      // user.state === "REGISTERED_GOOGLEFORMS" ||
      user.state === 'REGISTERED_APPROVED' &&
      user.role === 'ADMIN'
    ) {
      console.log('RESULT OF USEEFFECT', user);
      history.replace('/home');
    } else if (user.role === 'TEACHER' && user.role === 'STUDENT') {
      console.log('관리자가 아닌 계정입니다.');
    }
  }, [user.state]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      role: 'PARENT',
    },
    // validate: (values) => {
    //   const errors = {};
    //   if (!values.email) {
    //     errors.email = '이메일을 입력해주세요!';
    //   } else if (!isEmailvalid(values.email)) {
    //     errors.email = '이메일 형식이 올바르지 않습니다.';
    //   }
    //   if (!values.password) {
    //     errors.password = '비밀번호를 입력해주세요!';
    //   }
    //   return errors;
    // },

    onSubmit: (values) => {
      dispatch(
        signInByEmailPromise({
          email: values.email,
          password: values.password,
          role: values.role,
        })
      )
        .then((clientInfo) => {
          console.log('login');
          if (clientInfo === undefined) {
            throw new Error('login failed');
          }
          history.replace('/home');
          //loginHistory
          const response = loginHistory({
            clientId: clientInfo.clientId,
            loginAt: new Date(),
            // ipAddress: "",
          })
          // .then((res) => console.log('loginHistory', res))
          // console.log('loginHistory response', response)
        })
        .catch((error) => {
          dispatch(
            signInByEmailPromise({
              email: values.email,
              password: values.password,
              role: values.role,
            })
          )
            .then((clientInfo) => {
              console.log('login');
              if (clientInfo === undefined) {
                throw new Error('login failed');
              }
              history.replace('/home');
              //loginHistory
            })
            .catch((error) => {
              console.log(error);
              alert('로그인에 실패하였습니다.');
            });
        });
    },
  });

  const onSubmit = (e) => {
    const email = e.target.email.value;
    const email_trim = email.replace(/ /gi, '');
    const password = e.target.password.value;
    e.preventDefault();
    if (!isEmailvalid(email_trim)) {
      return;
    } else {
      if (password === '') {
        alert('비밀번호를 입력해 주세요!');
        return;
      }
      dispatch(
        signInByEmailPromise({
          email: email_trim,
          password: password,
          role: 'TEACHER',
        })
      )
        .then((clientInfo) => {
          console.log('TEACHER login');
          if (clientInfo === undefined) {
            throw new Error('login failed');
          }
          history.replace('/home');
        })
        .catch((error) => {
          console.log(error);
          dispatch(
            signInByEmailPromise({
              email: email_trim,
              password: password,
              role: 'STUDENT',
            })
          )
            .then((clientInfo) => {
              console.log('STUDENT login');
              if (clientInfo === undefined) {
                throw new Error('login failed');
              }
              history.replace('/home');
            })
            .catch((error) => {
              dispatch(
                signInByEmailPromise({
                  email: email_trim,
                  password: password,
                  role: 'PARENT',
                })
              )
                .then((clientInfo) => {
                  console.log('PARENT login');
                  if (clientInfo === undefined) {
                    throw new Error('login failed');
                  }
                  history.replace('/home');
                })
                .catch((error) => {
                  console.log('login fail');
                  alert(
                    `로그인에 실패했습니다. 이메일 또는 비밀번호를 확인해주세요.`
                  );
                });
            });
        });
    }
    console.log('confirm user', user);
  };

  return (
    <Box position={'relative'}>
      <Center h={'80vh'}>
        <Container
          as={SimpleGrid}
          maxW={'7xl'}
          columns={{ base: 1, md: 1, lg: 1 }}
          spacing={{ base: 10, lg: 32 }}
          centerContent
        >
          {/* <Stack spacing={{ base: 10, md: 20 }}>
            <Heading
              lineHeight={1.1}
              fontSize={{ base: '3xl', sm: '4xl', md: '5xl', lg: '6xl' }}
            ></Heading>
            <Stack direction={'row'} spacing={4} align={'center'}></Stack>
          </Stack> */}

          <Stack
            bg={'gray.50'}
            rounded={'xl'}
            p={{ base: 4, sm: 6, md: 8 }}
            spacing={{ base: 8 }}
            maxW={{ lg: 'lg' }}
          >
            <Stack spacing={4}>
              <Heading
                color={'gray.800'}
                lineHeight={1.1}
                fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
              >
                닥터
                <Text
                  as={'span'}
                  bgGradient="linear(to-r, red.400,pink.400)"
                  bgClip="text"
                >
                  {' '}
                  사이먼
                </Text>
              </Heading>
              <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
                자녀의 마음 건강과 공부에 대한 개인별 분석 서비스
              </Text>
            </Stack>
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing={4}>
                <FormControl id="email">
                  <FormLabel htmlFor="email" fontFamily={'Noto Sans KR'}>
                    이메일
                  </FormLabel>
                  <Input
                    bg={'gray.100'}
                    border={0}
                    color={'gray.500'}
                    _placeholder={{
                      color: 'gray.500',
                    }}
                    id="email"
                    name="email"
                    type="email"
                    variant="filled"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                </FormControl>
                <FormControl id="password">
                  <FormLabel htmlFor="email" fontFamily={'Noto Sans KR'}>
                    비밀번호
                  </FormLabel>
                  <Input
                    // placeholder="비밀번호"
                    // bg={'gray.100'}
                    // border={0}
                    // color={'gray.500'}
                    // _placeholder={{
                    //   color: 'gray.500',
                    // }}
                    id="password"
                    name="password"
                    type="password"
                    variant="filled"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                  />
                </FormControl>
              </Stack>
              <Button
                fontFamily={'heading'}
                mt={8}
                w={'full'}
                bgGradient="linear(to-r, red.400,pink.400)"
                color={'white'}
                _hover={{
                  bgGradient: 'linear(to-r, red.400,pink.400)',
                  boxShadow: 'xl',
                }}
                type="submit"
              >
                로그인
              </Button>
            </form>
            {/* <Flex>
              <Text
                color={'gray.500'}
                fontSize={{ base: 'sm', sm: 'md' }}
                mr={2}
              >
                아직 회원이 아니신가요?
              </Text>
              <Link
                href="#"
                color={'blue.400'}
                fontWeight={600}
                fontSize={{ base: 'sm', sm: 'md' }}
              >
                회원가입
              </Link>
            </Flex> */}
            {/* <Flex>
              <Text
                color={'gray.500'}
                fontSize={{ base: 'sm', sm: 'md' }}
                mr={2}
              >
                이메일/비밀번호를 잊으셨나요?
              </Text>
              <Link
                href="#"
                color={'blue.400'}
                fontWeight={600}
                fontSize={{ base: 'sm', sm: 'md' }}
              >
                이메일/비밀번호 찾기
              </Link>
            </Flex> */}
          </Stack>
        </Container>
      </Center>
    </Box>
  );
};

export default SignIn;
