import { Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

const GeneratedCommentsText = ({
  content,
  paragraphIndent,
  paragraphLineHeight,
}) => {
  return (
    <Text
      as="p"
      marginTop="6"
      color={useColorModeValue('gray.700', 'gray.200')}
      fontSize="lg"
      fontFamily={'Noto Serif KR'}
      textIndent={paragraphIndent}
      lineHeight={paragraphLineHeight}
    >
      {content}
    </Text>
  );
};

export default GeneratedCommentsText;
