import {
  PURGE_CONTROL,

  SET_APPROVAL_COMPLETED,
  SET_SIDEBAR_STATE,

} from './actionTypes';


///// PURGE_CONTROL
export const purgeControl = () => ({
  type: PURGE_CONTROL,
});


///// SET_APPROVAL_COMPLETED
export const setApprovalCompleted = () => {
  return {
    type: SET_APPROVAL_COMPLETED,
  };
};

///// SET_SIDEBAR_STATE
export const setSidebarState = () => {
  return {
    type: SET_SIDEBAR_STATE,
  };
};