import {
  PURGE_MEMBERSHIP,
  
  GET_MEMBERSHIP_SUCCESS,
  GET_MEMBERSHIP_FAIL,

  GET_MEMBERSHIPS_BY_GROUP_SUCCESS,
  GET_MEMBERSHIPS_BY_GROUP_FAIL,

  CREATE_MEMBERSHIP_SUCCESS,
  CREATE_MEMBERSHIP_FAIL,
} from './actionTypes';
import { createPromiseAction } from '@adobe/redux-saga-promise';

export const purgeMembership = () => ({
  type: PURGE_MEMBERSHIP,
});

///// GET_MEMBERSHIP BY CLIENT

export const getMembershipPromise = createPromiseAction(
  "GET_MEMBERSHIP_PROMISE"
);


export const getMembershipSuccess = (membershipData) => ({
  type: GET_MEMBERSHIP_SUCCESS,
  membershipData: membershipData,
});

export const getMembershipFail = (error) => ({
  type: GET_MEMBERSHIP_FAIL,
  payload: error,
});

///// GET_MEMBERSHIP BY GROUP

export const getMembershipsByGroupPromise = createPromiseAction(
  "GET_MEMBERSHIPS_BY_GROUP_PROMISE"
);

export const getMembershipsByGroupSuccess = (membershipsData) => {
  let byId = {};
  membershipsData.forEach((clientMembershipData) => {
    byId[`${clientMembershipData.membership.clientId},${clientMembershipData.membership.groupId}`] = clientMembershipData.membership});
    
  const allIds = membershipsData
    .map((clientMembershipData) => {
      return `${clientMembershipData.membership.clientId},${clientMembershipData.membership.groupId}`;
    });
  
  return {  
    type: GET_MEMBERSHIPS_BY_GROUP_SUCCESS,
    byId: byId,
    allIds: allIds,
    membershipsData: membershipsData,
  }
};

export const getMembershipsByGroupFail = (error) => ({
  type: GET_MEMBERSHIPS_BY_GROUP_FAIL,
  payload: error,
});

export const createMembershipPromise = createPromiseAction(
  "CREATE_MEMBERSHIP_PROMISE"
);

export const createMembershipSuccess = (membershipData) => ({
  type: CREATE_MEMBERSHIP_SUCCESS,
  membershipData: membershipData,
});

export const createMembershipFail = (error) => ({
  type: CREATE_MEMBERSHIP_FAIL,
  payload: error,
});