import { GetNiaStudentInformation } from '@api';
import { queryKeys } from '@app/react-query/constans';
import { useQuery, useQueries } from '@tanstack/react-query';

export function useStudentInfoByClientQuery(clientId) {
  const fallbackData = {};
  const { data = fallbackData, isLoading, isError } = useQuery(
    queryKeys.niaStudentInfo(clientId),
    () => GetNiaStudentInformation(clientId),
    {
      enabled: !!clientId,
    }
  );
  return { data, isLoading, isError };
}

export function useStudentInfoByClientQueries(childStudentData) {
  const fallbackData = {};
  const { data = fallbackData, isLoading, isError } = useQuery(
    childStudentData.map((childStudent) =>
      queryKeys.niaStudentInfo(childStudent.existingStudentId)
    ),
    () =>
      Promise.all(
        childStudentData.map((childStudent) =>
          GetNiaStudentInformation(childStudent.existingStudentId)
        )
      ),
    {
      enabled: !!childStudentData,
    }
  );
  return { data, isLoading, isError };
}
