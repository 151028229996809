import React from 'react';
import AuthManager from './AuthManager';
// import GroupManager from './GroupManager';

const MasterManager = () => {
  return (
    <AuthManager>
      {/* <GroupManager /> */}
      {/* <ClassManager />
      <BlockingManager />
      <ScreenManager />
      <FCMManager />
      <ArchiveManager />
      <SensorManager /> */}
    </AuthManager>
    );
}

export default MasterManager;