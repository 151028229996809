import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '@components/Layout/Sidebar';
import { setToken } from '@api';
import styles from './index.module.scss';
import InviteManagerTeacherModal from '@components/Modal/InviteManagerTeacherModal';
import InviteTeacherModal from '@components/Modal/InviteTeacherModal';
import {
  getAllManagerTeachersAdminPromise,
} from '@store/actions';
import {
  getAllTeachersAdminPromise,
  getSchoolPromise
} from '@store/actions';
import useInput from '../../hooks/useInput';
import CardManagerInfo from '@components/Card/CardManagerInfo';

const Manager = (props) => {
  const user = useSelector((state) => state.user);
  const list = useSelector((state) => state.clients.allManagerTeacher); // manager
  const teacherList = useSelector((state) => state.clients.onlyTeacher); // teacher
  const totalList = useSelector((state) => state.clients.allTeacher); // teacher + manager
  const [managerList, setManagetList] = useState([]);
  const [update, setUpdate] = useState();
  const [searchInput, onChangeSearchInput] = useInput('');
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const schoolData = useSelector((state) => state.schools.byId[user.schoolId]);
  const schools = useSelector((state) => state.schools);

  let schoolMaxData = 0;
  if (schoolData !== undefined && schoolData.maxTeacherAccount !== null) {
    schoolMaxData = schoolData.maxTeacherAccount;
  };

  let school_name = "";
  if (schoolData !== undefined) {
    school_name = schoolData?.name;
  }

  const managerNum = list?.length;
  const teacherNum = teacherList?.length;
  const totalNum = totalList?.length;

  useEffect(() => {
    //const schoolId = user.school;
    const schoolId = user.schoolId;
    if (schoolId == null) {
      //link to focuspang school
      dispatch(getSchoolPromise(1424))
    } dispatch(getSchoolPromise(schoolId))
  }, []);

  useEffect(() => {
    // console.log('list변경감지')
    return setManagetList(list);
  }, [list])

  const onSearch = useCallback(() => {
    if (searchInput === null || searchInput === '') {
      setUpdate(list);
    } else {
      // console.log('search', searchInput)
      //const result = list.filter(list => list.username.includes(searchInput) || list.email.includes(searchInput));
      const result = list.filter(list => list.client.userName.includes(searchInput) || list.account.email.includes(searchInput));
      // console.log(update)
      setUpdate(result);
    }
  }, [searchInput]);

  const handleOnKeyPress = e => {
    if (e.key === 'Enter') {
      onSearch();
    }
  };

  useEffect(() => {
    if (user.token !== undefined && user.token !== null) {
      setToken(user.token);
    }
  }, []);

  useEffect(() => {
    // console.log('useEffect getAllManagerTeacher')
    dispatch(getAllManagerTeachersAdminPromise(user.schoolId))
      .then((managerteachersData) => {
        if (managerteachersData === undefined) {
          throw new Error('already signed up!');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className={styles['main-container']}>
      <Sidebar auth={true} />
      <div className={styles['top-container']}>
        <div className={styles['box']}>
          <div>
            <div className={styles['title-text']}>
              <a href='/manager'>관리자 계정 관리</a>
            </div>
            <div className={styles['license-text']}>
              (관리자{managerNum}명, 선생님 {teacherNum}명, 총사용 {totalNum}명 / 최대가능 {schoolMaxData}명)
            </div>
          </div>
          <div className={styles['top-container-list']}>
            <input
              className={styles["member-search"]}
              type="text"
              placeholder="관리자 이름 또는 이메일"
              value={searchInput}
              onChange={onChangeSearchInput}
              onKeyPress={handleOnKeyPress}
            />
            <button
              className={styles['search-filter']}
              type="submit"
              onClick={onSearch}
            >
              검색
            </button>
          </div>
        </div>
      </div>

      <div className={styles['bottom-container']}>
        <div className={styles['bottom-container-list']}>
          <div className={styles['userName']}>이름</div>
          <div className={styles['userEmail']}>이메일</div>
          <div className={styles['userState']}>계정상태</div>
          <div className={styles['userAdd']}>
            <button
              className={styles['userInvite-button']}
              onClick={() => {
                if (totalNum >= schoolMaxData) {
                  alert('최대 ' + schoolMaxData + '명의 선생님만 등록할 수 있습니다. 계정 추가 신청은 문의부탁드립니다.');
                  console.log("user add cancelled");
                  return;
                }
                setShow(true);
              }}
            >
              관리자 등록하기
            </button>
          </div>
        </div>
        {update ?
          update?.map((data) => (
            <CardManagerInfo
              key={data.account.accountId}
              data={data}
              username={data.client.userName}
              email={data.account.email}
              user_id={data.account.accountId}
              school_id={data.client.schoolId}
              user_state={data.client.state} />
          ))
          : managerList?.map((data) => (
            <CardManagerInfo
              key={data.account.accountId}
              data={data}
              username={data.client.userName}
              email={data.account.email}
              user_id={data.account.accountId}
              school_id={data.client.schoolId}
              user_state={data.client.state} />
          ))
        }
      </div>

      <InviteManagerTeacherModal 
        show={show} 
        setShow={setShow} 
        school_id={user.schoolId} 
      />
      <br />
    </div>
  );
};

export default Manager;
