import React from 'react';
import {
  Box,
  Heading,
  Link,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  useColorModeValue,
  Container,
  VStack,
} from '@chakra-ui/react';
import {
  paragraphIndent,
  paragraphLineHeight,
  paragraphSpacing,
} from '@app/assets/theme/typoStyle';

const BlogTags = (props) => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map((tag) => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

export const BlogAuthor = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src="https://100k-faces.glitch.me/random-image"
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

const BlogPageOne = () => {
  return (
    <Container maxW={'7xl'} p="8" mb={'6'}>
      {/* <Heading as="h1" fontFamily={'Noto Sans KR'}>
        닥터 사이먼의 학습과 마음
      </Heading>
      <Divider marginTop="5" /> */}
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between"
      >
        {/* <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          // alignItems="center"
          >
          <Box
            width={{ base: '100%', sm: '85%' }}
            zIndex="2"
            marginLeft={{ base: '0', sm: '5%' }}
            marginTop="5%">
            <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
              <Image
                borderRadius="lg"
                src={
                  'https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=800&q=80'
                }
                alt="닥터 사이먼"
                objectFit="contain"
              />
            </Link>
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box
              backgroundSize="20px 20px"
              opacity="0.4"
              height="100%"
            />
          </Box>
        </Box> */}
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}
        >
          {/* <BlogTags tags={['태그 샘플', '태그추가하기']} /> */}
          <Heading marginTop="1">
            <Link textDecoration="none" _hover={{ textDecoration: 'none' }} fontFamily={'Noto Serif KR'}>
              1. 우리 아이의 공부, 우리 아이의 마음
            </Link>
          </Heading>
          <Text
            as="p"
            marginTop="6"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            // 문단 간격 벌리기
            marginBottom={paragraphSpacing}
            // 들여쓰기
            textIndent={paragraphIndent}
            // 줄간격
            lineHeight={paragraphLineHeight}
          >
            <Text fontWeight={'bold'} display={'inline'}>
              생의 성장 기반을 다지는 청소년기에는
            </Text>{' '}
            신체적, 정서적, 심리적으로 큰 변화가 일어나는 시기이므로, 마음의
            건강이 매우 중요합니다. 이 시기의 청소년은 인터넷게임 중독뿐만
            아니라 충동, 불안, 우울 같은 마음 건강 문제에 더 취약합니다. 이
            시기에 제대로 관리받지 못하면, 그대로 성인기로 이어지며 더욱 큰
            문제가 되는 경우가 많습니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            // 문단 간격 벌리기
            marginBottom={paragraphSpacing}
            // 들여쓰기
            textIndent={paragraphIndent}
            // 줄간격
            lineHeight={paragraphLineHeight}
          >
            마음 건강은 청소년의 집중력, 정보 유지력, 학습 자료 이해와 활용
            능력에도 큰 영향을 미칩니다. 마음 건강 장애는 주의력, 기억력, 동기
            부여, 학업 성취도에 문제를 일으킬 수 있습니다. 또한 학교 기피,
            사회적 위축 및 학습을 방해하는 기타 행동으로 이어질 수 있습니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            // 문단 간격 벌리기
            marginBottom={paragraphSpacing}
            // 들여쓰기
            textIndent={paragraphIndent}
            // 줄간격
            lineHeight={paragraphLineHeight}
          >
            정리하면, 아이의 마음은 아이가 공부를 잘 하게 되는 것뿐만 아니라,
            행복하게 성장하는데 중요합니다. 특히 청소년기는 마음 건강 문제를 잘
            관리해야 합니다. 그래야 오랜 기간후에 나타날 수 있는 부정적인 결과를
            예방하고, 미래의 긍정적인 결과를 촉진할 수 있습니다. 마음 건강
            문제를 경험하는 청소년은 성적이 낮아질 가능성이 높으며 학교생활이
            어렵게 되기도 하고, 고등 교육이나 직장으로 전환하는 데 어려움을 겪을
            수 있습니다.
          </Text>
          {/* <BlogAuthor name="규찬" date={new Date('2021-04-06T19:01:27Z')} /> */}
        </Box>
      </Box>
      {/* 
      <VStack paddingTop="40px" spacing="2" alignItems="flex-start">
        <Box
          width={{ base: '100%', sm: '85%' }}
          zIndex="2"
          marginLeft={{ base: '0', sm: '5%' }}
          marginTop="5%"
        >
          <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
            <Image
              borderRadius="lg"
              src={
                'https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=800&q=80'
              }
              alt="닥터 사이먼"
              objectFit="contain"
            />
          </Link>
        </Box>
        <Heading as="h2" fontFamily={'Noto Sans KR'}>
          타이틀
        </Heading>
        <Text as="p" fontSize="lg" fontFamily={'Noto Sans KR'}>
          넷째1231231232131232
        </Text>
        <Text as="p" fontSize="lg" fontFamily={'Noto Sans KR'}>
          셋째
        </Text>
        <Text as="p" fontSize="lg" fontFamily={'Noto Sans KR'}>
          만약 난독증이 있다면 글자사이에, 단어사이에 적절한 띄어쓰기뿐만
          아니라간격을 좀 넓혀 보는 것도 도움이 된다고 합니다. 그렇지만 자녀의
          읽기이해가 충분하고 빠르게 읽을 수 있다면, 간격을 넓히는 건 오히려
          방해가 된다고 합니다. 참고문헌
        </Text>
      </VStack> */}
    </Container>
  );
};

export default BlogPageOne;
