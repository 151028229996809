import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { purgeAll } from '@store/actions';
import { refreshToken } from '@api';

const AuthManager = ({ children }) => {
  const user = useSelector((state) => state.user);
  const client = useSelector((state) => state.clients.state);
  const control = useSelector((state) => state.control);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [passthrough, setPassthrough] = useState(false);
  const updated = useSelector((state) => state.user.updated);

  const [userValidated, setUserValidated] = useState(false);
  useEffect(() => {
    if (user.signedIn) {
      if (!userValidated) {
        setUserValidated(true);
      }
      if (!updated) {
        dispatch(purgeAll());
      }
    }
    if (!user.signedIn) {
      setPassthrough(false);
      setUserValidated(false);
      console.log('[AuthManager]', 'user.signedIn false');
      history.replace('/auth');
    }

    if (user?.state == null) {
      console.log('[AuthManager]', 'user.state null');
      return;
    }
  }, [user]);

  useEffect(() => {
    if (control.released) {
      setPassthrough(false);
      setUserValidated(false);
      console.log('[AuthManager]', 'control released:', control);
    }
  }, [control.released]);

  const [prevUserState, setPrevUserState] = useState(null);
  useEffect(() => {
    if (user?.state == null) {
      return;
    }

    if (prevUserState === user.state) {
      return;
    }

    console.log('[AuthManager]', prevUserState, '->', user.state);
    setPrevUserState(user.state);
    refreshToken();

    switch (user.state) {
      case 'NOT_SIGNED_IN':
        history.replace('/auth');
        return;

      case 'REGISTERED_APPROVED':
        setUserValidated(true);
        return;

      default:
        console.log('[AuthManager]', 'invalid user state:', user.state);

        setUserValidated(true);
        return;
    }
  }, [user.state]);

  const [permissionValidated, setPermissionValidated] = useState(false);
  useEffect(() => {
    if (!userValidated) {
      setPermissionValidated(false);
      return;
    }

    console.debug('[AuthManager]', 'control:', control);

    setPassthrough(true);
    setPermissionValidated(true);

    console.log('[AuthManager]', 'all permissions granted, navigating to ClassStack');
    if (location.pathname.includes('auth')) {
      history.replace('/home');
    }
  }, [userValidated, control, user.state]);

  return <div>{userValidated && permissionValidated && children}</div>;
};

export default AuthManager;
