import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Sidebar from '@components/Layout/Sidebar';
import styles from './index.module.scss';
import { changePasswordPromise, setSidebarState, getSchoolPromise } from '@store/actions';
import { setToken, getStorageData, useInterval } from '@api';

const Settings = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const control = useSelector((state) => state.control);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const schoolId = user.schoolId;
    if (schoolId == null) {
      //link to focuspang school
      dispatch(getSchoolPromise(1424))
    } dispatch(getSchoolPromise(schoolId))
  }, []);

  useEffect(() => {
    if (user.token !== undefined || user.token !== null) {
      setToken(user.token).then(() => {
        try {
          console.log('set token to program...');
          window.cAPI.setToken(user.token);
        } catch (error) {}
      });
    }
    if (user.state === 'REGISTERED_APPROVED') {
      if (control.sidebarState === false) dispatch(setSidebarState());
    } else {
      //dispatch(setSidebarState(false));
    }
    // console.log('[Setting : ', control);
  }, [user, control]);
 
  return (
    <div className={styles['main-container']}>
      <Sidebar />
      <div className={styles['settings-container']}>
        <div className={styles['settings-title']}>Title</div>
      </div>
    </div>
  );
};

export default Settings;
