export function splitTextIntoObjects(text) {
  const lines = text.split('\n');
  const textObjects = lines
    .map((line, index) => {
      if (line.trim() === '') {
        return null; // 빈 줄인 경우 null 반환
      } else {
        return { id: index, content: line };
      }
    })
    .filter(Boolean); // null 제거

  return textObjects;
}
