import React from 'react';
import {
  Box,
  Heading,
  Link,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  useColorModeValue,
  Container,
  VStack,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import Image5 from '@app/assets/images/chart4.png';
import Image6 from '@app/assets/images/pagethree_6.png';
import Image7 from '@app/assets/images/pagethree_7.png';
import {
  paragraphIndent,
  paragraphLineHeight,
  paragraphSpacing,
  WIDTHS,
  WIDTHS2,
} from '@app/assets/theme/typoStyle';

const BlogTags = (props) => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map((tag) => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

export const BlogAuthor = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src="https://100k-faces.glitch.me/random-image"
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

const BlogPageFour = () => {
  return (
    <Container maxW={'7xl'} p="8">
      {/* <Heading as="h1" fontFamily={'Noto Sans KR'}>닥터 사이먼의 학습과 마음</Heading>
            <Divider marginTop="5" /> */}
      {/* page4 내용 */}
      {/* <Box
                marginTop={{ base: '1', sm: '5' }}
                display="flex"
                flexDirection={{ base: 'column', sm: 'row' }}
                justifyContent="space-between">
                <Box
                    display="flex"
                    flex="1"
                    marginRight="3"
                    position="relative"
                    // alignItems="center"
                    >
                    <Box
                        width={{ base: '100%', sm: '85%' }}
                        zIndex="2"
                        marginLeft={{ base: '0', sm: '5%' }}
                        marginTop="5%">
                        <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
                            <Image
                                borderRadius="lg"
                                src={
                                    'https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=800&q=80'
                                }
                                alt="닥터 사이먼"
                                objectFit="contain"
                            />
                        </Link>
                    </Box>
                    <Box zIndex="1" width="100%" position="absolute" height="100%">
                        <Box
                            backgroundSize="20px 20px"
                            opacity="0.4"
                            height="100%"
                        />
                    </Box>
                </Box>
                <Box
                    display="flex"
                    flex="1"
                    flexDirection="column"
                    justifyContent="center"
                    marginTop={{ base: '3', sm: '0' }}>
                    <Heading marginTop="1">
                        <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
                            4. 내 아이의 충동성과 공부
                        </Link>
                    </Heading>
                    <Text
                        as="p"
                        marginTop="2"
                        color={useColorModeValue('gray.700', 'gray.200')}
                        fontSize="lg"
                        fontFamily={'Noto Sans KR'}>
                        그럼 우리 아이는 어떨까요.
                    </Text>
                    <Text
                        as="p"
                        marginTop="2"
                        // color={useColorModeValue('gray.700', 'gray.200')}
                        color={'red'}
                        fontSize="lg"
                        fontFamily={'Noto Sans KR'}>
                        개별 아이의 부모에게 보내는 글의 샘플
                    </Text>
                    <Text
                        as="p"
                        marginTop="2"
                        color={useColorModeValue('gray.700', 'gray.200')}
                        fontSize="lg"
                        fontFamily={'Noto Sans KR'}>
                        분석결과를 종합해보면 학생의 개념 이해 능력이 높고 학습태도도 우수한 편입니다. 또한 충동성도 낮은 편이지만, 세부사항을 놓치는 경향이 있어서 개선이 필요합니다. 따라서 앞으로 성적과 개념 이해, 학습태도, 충동성, 분석적 사고력 개발을 위한 개선점은 다음과 같습니다.
                    </Text>
                    <Text
                        as="p"
                        marginTop="2"
                        color={useColorModeValue('gray.700', 'gray.200')}
                        fontSize="lg"
                        fontFamily={'Noto Sans KR'}>
                        세부사항을 놓치지 않도록 노력해야 합니다. 과제나 시험 준비를 할 때, 조금 더 꼼꼼히 준비하고 복습하는 습관을 길러야 합니다. 개념 이해 능력이 뛰어나기 때문에, 학습 내용을 이해하는 것에 집중하면서 더 깊은 수준에서 이해하고자 노력해야 합니다. 개념 이해 능력을 활용하여 문제 해결 능력을 높일 수 있습니다.
                    </Text>
                    <Text
                        as="p"
                        marginTop="2"
                        color={useColorModeValue('gray.700', 'gray.200')}
                        fontSize="lg"
                        fontFamily={'Noto Sans KR'}>
                    충동성이 낮은 편이지만, 때로는 느긋하게 일을 처리하다가 시간이 부족해져서 급하게 일을 처리하는 경우가 있을 수 있습니다. 따라서, 계획적인 학습 습관을 길러야 합니다. 일정을 미리 계획하고, 그 일정에 맞춰서 계획적으로 학습하는 것이 중요합니다.
                    </Text>
                    <Text
                        as="p"
                        marginTop="2"
                        color={useColorModeValue('gray.700', 'gray.200')}
                        fontSize="lg"
                        fontFamily={'Noto Sans KR'}>
                    분석적 사고력을 높이기 위해서는, 세부사항을 놓치지 않도록 노력하는 것이 중요합니다. 또한, 문제 해결 능력을 높이기 위해서는 문제를 분석하고, 문제를 해결하는 과정에서 어떤 과정을 거쳐야 하는지를 이해하고 구체적으로 계획을 세우는 것이 중요합니다.
                    </Text>
                    <Text
                        as="p"
                        marginTop="2"
                        color={useColorModeValue('gray.700', 'gray.200')}
                        fontSize="lg"
                        fontFamily={'Noto Sans KR'}>
                    부모님께서는 학생의 장점과 노력을 인정해주시고, 개선점에 대해서는 격려와 함께 지도해주시면 좋을 것입니다. 학생이 더욱 노력하고 성장할 수 있도록 응원해주시면 좋을 것입니다.
                    </Text>
                </Box>
            </Box> */}
      {/* page3 내용1 */}
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between"
      >
        {/* <Box
                    display="flex"
                    flex="1"
                    marginRight="3"
                    position="relative"
                    // alignItems="center"
                >
                    <Box
                        width={{ base: '100%', sm: '85%' }}
                        zIndex="2"
                        marginLeft={{ base: '0', sm: '5%' }}
                        marginTop="5%"
                    >
                        <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
                            <Image
                                borderRadius="lg"
                                src={Image5}
                                alt="닥터 사이먼"
                                objectFit="contain"
                            />
                        </Link>
                    </Box>
                    <Box zIndex="1" width="100%" position="absolute" height="100%">
                        <Box backgroundSize="20px 20px" opacity="0.4" height="100%" />
                    </Box>
                </Box> */}
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}
        >
          <Heading marginTop="1">
            <Link textDecoration="none" _hover={{ textDecoration: 'none' }} fontFamily={'Noto Serif KR'}>
              4. 닥터 사이먼의 솔루션: 누구나 할 수 있는 띄어쓰기!
            </Link>
          </Heading>
          <Link textDecoration="none" _hover={{ textDecoration: 'none' }} display={'flex'} justifyContent={'center'}>
            <Image
              borderRadius="lg"
              src={Image5}
              alt="닥터 사이먼"
              objectFit="contain"
              marginTop="6"
              width={WIDTHS2}
            />
          </Link>
          <Text
            as="p"
            marginTop="6"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
          >
            비판적 사고(critical thinking)는 앞서 언급한 것처럼, 주어진 정보를
            분석 평가하고, 기본 가정을 파악하고, 보이지 않는 정보를 엮어서
            추론하여, 논리적 결론에 도달하는 능력이라는 복잡하게 말하지만, 결국
            기본적으로 정보를 정확하게 파악하는 데 필요합니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            이번 섹션에는 충동적인 학생이 이런 고급 기술을 쉽게 연마할 수 있는
            띄어쓰기에 대해서 살펴보겠습니다.{' '}
            <Text fontWeight={'bold'} color={'orange'} display={'inline'}>
              그래프에 추가된 주황색 선이 띄어쓰기입니다.
            </Text>{' '}
            보고서를 작성하거나 답안지를 작성할 때, 띄어쓰기는 무시할 수
            없습니다. 그런데 충동적인 아이들에게 띄어쓰기는 여간 번거로운 게
            아닙니다. 그래서 띄어쓰기를 잘 안 합니다. 그러면 어떤 일이
            일어날까요? 결코 좋은 점수를 받을 수 없습니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            이번 그래프에도 잘 나타납니다.{' '}
            <Text fontWeight={'bold'} display={'inline'}>
              충동성을 나타내는 붉은색 선과 비교해 보면, 충동성이 낮을수록
              답안지 띄어쓰기를 잘하고, 충동성이 높을수록 띄어쓰기를 하지
              않습니다.
            </Text>{' '}
            띄어쓰기는 단적인 예일뿐 오타라거나 마침표 등, 디테일에 신경 쓰며
            제대로 마무리하지 않게 되니 아무리 공부를 열심히 해도 성적은 나쁠
            수밖에 없습니다. 그러면 이 문제는 더욱 심각해집니다. 그래프를 잘
            보시면, 상위 1 등급 학생에 비해 하위 10 등급 학생의 띄어쓰기는 평균
            50% 수준밖에 되지 않습니다. 물론 띄어쓰기를 잘하는 학생들의 개념
            이해도나 성적은 모두 높게 나타납니다. 띄어 쓰지 않는 서울대생?
            찾아보기 쉽지 않겠지요.
          </Text>
        </Box>
      </Box>
      {/* page3 내용2 */}
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between"
      >
        {/* <Box
                    display="flex"
                    flex="1"
                    marginRight="3"
                    position="relative"
                    // alignItems="center"
                >
                    <Box
                        width={{ base: '100%', sm: '85%' }}
                        zIndex="2"
                        marginLeft={{ base: '0', sm: '5%' }}
                        marginTop="5%"
                    >
                        <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
                            <Image
                                borderRadius="lg"
                                src={Image6}
                                alt="닥터 사이먼"
                                objectFit="contain"
                            />
                            <Link
                                fontSize="lg"
                                fontFamily={'Noto Sans KR'}
                                href='http://news.heraldcorp.com/view.php?ud=20120923000015'
                                isExternal
                                my={'4'}
                            >
                                출처: 헤럴드경제(2012.09.23)<ExternalLinkIcon mx='2px' />
                            </Link>
                        </Link>
                    </Box>
                    <Box zIndex="1" width="100%" position="absolute" height="100%">
                        <Box backgroundSize="20px 20px" opacity="0.4" height="100%" />
                    </Box>
                </Box> */}
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}
        >
          <Link textDecoration="none" _hover={{ textDecoration: 'none' }} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
            <Image
              borderRadius="lg"
              src={Image6}
              alt="닥터 사이먼"
              objectFit="contain"
              marginTop="2"
              width={WIDTHS}
            />
            <Link
              fontSize="sm"
              fontFamily={'Noto Serif KR'}
              href="http://news.heraldcorp.com/view.php?ud=20120923000015"
              isExternal
              my={'4'}
            >
              출처: 헤럴드경제(2012.09.23)
              <ExternalLinkIcon mx="2px" />
            </Link>
          </Link>
          <Text
            as="p"
            marginTop="6"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            fontWeight={'bold'}
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            왜 띄어쓰기가 중요할까?
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            <Text fontWeight={'bold'} display={'inline'}>
              우리는 말보다 글로 소통합니다.
            </Text>{' '}
            카카오톡 같은 메신저를 읽고 쓰고, 언론 기사든지 책이든지 하루 종일
            읽습니다. 전문직 종사자는 대부분의 시간을 보고서를 작성하고,
            발표자료를 만들며 보냅니다. 심지어 프로그래머도 컴퓨터에게 글을 쓰며
            보냅니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            글은 현대인의 의사소통을 가능하게 하는 수단으로서, 기본적인 이해와
            표현의 단위를 만듭니다. 글을 읽을 때 결국 단어 사이의 공간 덕분에
            세부 사항에 주의를 기울이고, 문맥을 고려하고, 가정에 의문을 제기하는
            데 도움이 됩니다. 즉 정보를 분석할 수 있게 됩니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            그래서{' '}
            <Text fontWeight={'bold'} display={'inline'}>
              띄어 쓰지 않는 경향은 단어와 단어를 띄어서 읽지 못하는 경향과
              상당히 관련이 있습니다.
            </Text>{' '}
            띄어쓰기를 하지 않는 학생들은 문제를 읽을 때 세부 사항을 파악하지
            않는 습관을 가지게 될 수 있습니다. 굳이 난독증까지 가지 않더라도, 글
            이해에 문제가 생깁니다. 제대로 읽지 못한다는 것은 심각한 문제가
            됩니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            textIndent={paragraphIndent} // 들여쓰기
          >
            <Text fontWeight={'bold'} display={'inline'}>
              띄어쓰기는 비판적 사고를 배양하는 데도 도움이 됩니다.
            </Text>{' '}
            비판적 사고를 위해서는 세부 사항에 대해 주의하고 분석할 수 있어야
            합니다. 그래서 정보를 파악하거나 문장을 읽을 때 단어 사이에
            띄어쓰기를 하면 개별 단어를 더 잘 구분하고 서로의 관계를 이해하는 데
            도움이 될 수 있습니다. 이는 구두점이나 명확한 경계로 단어가 항상
            구분되지 않는 글에서 특히 중요합니다. 그래서 만약 아이에게 난독증이
            있다면 글자 사이와 단어 사이의 간격을 좀 더 넓히면 도움이 된다고
            합니다.
          </Text>
          <Link
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            href="https://www.sciencedirect.com/science/article/pii/S0959475221001353"
            isExternal
            my={'4'}
            textIndent={paragraphIndent} // 들여쓰기
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            참고 사이트
            <ExternalLinkIcon mx="2px" />
          </Link>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            <Text fontWeight={'bold'} display={'inline'}>
              비판적 사고(critical thinking)를 하려면 스스로 가진 생각과 가정에
              의문을 제기하고 대안적인 설명을 고려할 수 있어야 합니다.
            </Text>{' '}
            이를 위해서는 문장을 쓰면서 다양한 해석을 열어 놓아야 하며, 단어와
            문장이 다른 순서로 배열할 수 있어야 합니다. 즉, 단어 사이의 공백을
            보면서 단어를 마치 부품처럼 이리저리 옮기고 다양한 어순을 더 쉽게
            실험하고 문장을 이해하고 표현하는 시도를 할 수 있습니다.
          </Text>
        </Box>
      </Box>
      {/* page3 내용3 */}
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between"
      >
        {/* <Box
                    display="flex"
                    flex="1"
                    marginRight="3"
                    position="relative"
                    // alignItems="center"
                >
                    <Box
                        width={{ base: '100%', sm: '85%' }}
                        zIndex="2"
                        marginLeft={{ base: '0', sm: '5%' }}
                        marginTop="5%"
                    >
                        <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
                            <Image
                                borderRadius="lg"
                                src={Image7}
                                alt="닥터 사이먼"
                                objectFit="contain"
                            />
                        </Link>
                    </Box>
                    <Box zIndex="1" width="100%" position="absolute" height="100%">
                        <Box backgroundSize="20px 20px" opacity="0.4" height="100%" />
                    </Box>
                </Box> */}
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}
        >
          <Link textDecoration="none" _hover={{ textDecoration: 'none' }} display={'flex'} justifyContent={'center'}>
            <Image
              borderRadius="lg"
              src={Image7}
              alt="닥터 사이먼"
              objectFit="contain"
              marginTop="2"
              width={WIDTHS}
            />
          </Link>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            fontWeight={'bold'}
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            누구나 쉽게 할 수 있는 띄어쓰기
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            <Text fontWeight={'bold'} display={'inline'}>
              이렇게 중요한 띄어쓰기지만, 누구나 쉽게 배울 수 있고, 쉽게 가르칠
              수 있습니다.
            </Text>{' '}
            특히 충동적인 아이들은 디테일을 무시하며, 띄어 쓰지 않기에
            띄어쓰기를 가르치면 효과적일 수 있습니다. 띄어쓰기를 잘 익히면 시험
            문제를 읽을 때, 세부 사항을 볼 수 있는 역량을 키울 수 있고, 보다
            깔끔하고 이해하기 쉬운 답안지를 작성할 수 있습니다. 당연히 학업
            점수를 높일 수 있습니다. 왜냐하면, 채점하는 선생님에게 내용을
            정확하게 전달할 뿐만 아니라, 그의 신경을 거스리지 않기 때문입니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            <Text fontWeight={'bold'} display={'inline'}>
              더불어 꼼꼼하고 성실성을 추구하는 태도를 기를 수 있습니다.
            </Text>{' '}
            우수 학생의 경우 띄어쓰기를 못 하는 경우는 볼 수 없습니다. 이에
            더해, 적절한 단어를 쓰고, 영어, 숫자, 한국어, 특수기호를 적절하게
            선택하여 사용합니다. 충동성이 심한 아이들은 이런 규칙을 따르지 않고
            스파게티같이 죽 이어진 글을 씁니다. 어쩌면 이런 성실한 태도는 성적이
            떨어져도 범죄나 비행에 충동을 느끼게 되는 충동을 통제하게 할 수
            있을지도 모릅니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            // textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            오늘은 여기까지입니다. 긴 보고서를 읽느라 고생 많으셨습니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            // textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            어떠셨나요? 우리 아이를 지켜내느라 고생하신 부모님들이 아이의 마음과
            공부를 이해하는 데 도움이 되었으면 좋겠습니다
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            // textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            다음 주에는 더욱 흥미로운{' '}
            <Text fontWeight={'bold'} display={'inline'}>
              개인 학생별 분석보고서
            </Text>
            를 보내드리겠습니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            // textIndent={paragraphIndent} // 들여쓰기
          >
            궁금하신 점은 카카오톡 채널에 문의를 남겨주시기를 바랍니다.
          </Text>
          <Link
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            href="http://pf.kakao.com/_xoEjHxj"
            isExternal
            my={'4'}
            // textIndent={paragraphIndent} // 들여쓰기
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            포커스팡 학부모 카카오톡 채널
            <ExternalLinkIcon mx="2px" />
          </Link>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            // textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            감사합니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            // textIndent={paragraphIndent} // 들여쓰기
            lineHeight={paragraphLineHeight} // 줄간격
            marginBottom={paragraphSpacing} // 문단 간격 벌리기
          >
            닥터 사이먼 드림
          </Text>
        </Box>
      </Box>
    </Container>
  );
};

export default BlogPageFour;
