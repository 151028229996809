import React from 'react';
import {
  Box,
  Heading,
  Link,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  useColorModeValue,
  Container,
  VStack,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import Image1 from '@app/assets/images/pagetwo_1.png';
import Image2 from '@app/assets/images/pagetwo_2.png';
import Image3 from '@app/assets/images/pagetwo_3.png';
import Image4 from '@app/assets/images/pagethree_1.png';
import {
  paragraphIndent,
  paragraphLineHeight,
  paragraphSpacing,
  WIDTHS,
  WIDTHS3,
} from '@app/assets/theme/typoStyle';

const BlogTags = (props) => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map((tag) => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

export const BlogAuthor = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src="https://100k-faces.glitch.me/random-image"
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

const BlogPageTwo = () => {
  return (
    <Container maxW={'7xl'} p="8" mb={'6'}>
      {/* <Heading as="h1" fontFamily={'Noto Sans KR'}>
        닥터 사이먼의 학습과 마음
      </Heading>
      <Divider marginTop="5" /> */}
      {/* box1 */}
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between"
      >
        {/* <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          // alignItems="center"
        >
          <Box
            width={{ base: '100%', sm: '85%' }}
            zIndex="2"
            marginLeft={{ base: '0', sm: '5%' }}
            marginTop="5%"
          >
            <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
              <Image
                borderRadius="lg"
                src={Image1}
                alt="닥터 사이먼"
                objectFit="contain"
              />
            </Link>
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box backgroundSize="20px 20px" opacity="0.4" height="100%" />
          </Box>
        </Box> */}
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}
        >
          {/* <BlogTags tags={['태그 샘플', '태그추가하기']} /> */}
          <Heading marginTop="1">
            <Link textDecoration="none" _hover={{ textDecoration: 'none' }} fontFamily={'Noto Serif KR'}>
              2. 공부를 어렵게 만드는 충동적 마음
            </Link>
          </Heading>
          <Link textDecoration="none" _hover={{ textDecoration: 'none' }} display={'flex'} justifyContent={'center'}>
            <Image
              borderRadius="lg"
              src={Image1}
              alt="닥터 사이먼"
              objectFit="contain"
              marginTop={'2'}
              width={WIDTHS}
            />
          </Link>
          <Text
            as="p"
            marginTop="6"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            // 문단 간격 벌리기
            marginBottom={paragraphSpacing}
            // 들여쓰기
            textIndent={paragraphIndent}
            // 줄간격
            lineHeight={paragraphLineHeight}
          >
            먼저 그래프 하나 보시지요. 속도와 정확도의 트래이드오프라는 심리학
            이론입니다. 기본적으로 속도를 빠르게 하면 정확도가 떨어지고,
            정확도를 높이면 속도가 느려진다는 것입니다. 정확하게 답을 하려고
            하면 할수록 오류나 실수가 줄어들지만, 빠르게 답하려고 할 수록
            자연스럽게 오류와 실수가 늘어나게 됩니다. 물론 궁극적으로는 정확도를
            높이면서, 속도를 높이는 방향으로 가야 하겠지만, 쉽지 않은
            과정입니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            marginBottom={paragraphSpacing}
            // 들여쓰기
            textIndent={paragraphIndent}
            // 줄간격
            lineHeight={paragraphLineHeight}
          >
            인공지능 시대이고 창의성을 강조하지만, 여전히{' '}
            <Text fontWeight={'bold'} display={'inline'}>
              학교 공부는 정확성에 무게를 둡니다.
            </Text>{' '}
            인공지능 시대이고 창의성을 강조하지만, 여전히 학교 공부는 정확성에
            무게를 둡니다. 시험이 단적인 예입니다. 그래서 비판적 사고(critical
            thinking)를 중요하게 생각합니다. critical을 “비판적”이라고 번역하는
            바람에, 마치 토론을 하며 비판을 할 수 있는 역량이란 느낌을 주지만,
            근본적으로 비판적 사고는 정보를 분석하고 평가하는 사고력을
            의미합니다. 세상이 어떻게 변화하건, 비판적 사고력은 학교와 직업
            생활, 특히 전문직 종사자에게는 상당히 중요한 역량입니다.
          </Text>
        </Box>
      </Box>
      {/* box2 */}
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between"
      >
        {/* <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          // alignItems="center"
        >
          <Box
            width={{ base: '100%', sm: '85%' }}
            zIndex="2"
            marginLeft={{ base: '0', sm: '5%' }}
            marginTop="5%"
          >
            <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
              <Image
                borderRadius="lg"
                src={Image2}
                alt="닥터 사이먼"
                objectFit="contain"
              />
            </Link>
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box backgroundSize="20px 20px" opacity="0.4" height="100%" />
          </Box>
        </Box> */}
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}
        >
          <Link textDecoration="none" _hover={{ textDecoration: 'none' }} display={'flex'} justifyContent={'center'}>
            <Image
              borderRadius="lg"
              src={Image2}
              alt="닥터 사이먼"
              objectFit="contain"
              width={WIDTHS3}
            />
          </Link>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            marginBottom={paragraphSpacing}
            // 들여쓰기
            textIndent={paragraphIndent}
            // 줄간격
            lineHeight={paragraphLineHeight}
          >
            그런데 이러한 비판적 사고는 대부분의 청소년에게 쉽지 않습니다.
            왜냐하면, 청소년기에는 마치 어디로 튈지 모르는 럭비공 같은 마음을
            가지기 때문입니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            marginBottom={paragraphSpacing}
            // 들여쓰기
            textIndent={paragraphIndent}
            // 줄간격
            lineHeight={paragraphLineHeight}
          >
            <Text fontWeight={'bold'} display={'inline'}>
              이런 마음 문제는 충동성(impulsivity) 때문입니다.
            </Text>{' '}
            아마 충동구매란 말이 생각날 것입니다. 뭔가 깊이 생각하지 않고 물건을
            사는 충동적 구매처럼, 충동성이란 어떤 결과가 일어날지 미리 고려하지
            않고, 성급하게 행동하는 경향을 말합니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
          >
            <Text fontWeight={'bold'} display={'inline'}>
              충동적인 아이들은 학교 성적이 좋지 못한 경향이 있습니다.
            </Text>{' '}
            비판적인 사고(critical thinking)를 할 수 있어야 하고, 정확한 답을
            해야 합니다. 그런데 충동적인 아이는 학습에 집중하거나, 체계적으로
            정리하거나, 과제를 완수하는 데 어려움을 겪습니다. 그래서 쉽게 주의가
            산만해지고, 한 과제를 끝내지 않고 다른 과제로 넘어가거나, 장기
            프로젝트를 끝까지 완수하는 데 어려움을 겪습니다. 그래서 학습 내용을
            이해하기 어렵고, 성적이 떨어지고, 다시 학습 의욕이 사라져갑니다.
          </Text>
        </Box>
      </Box>
      {/* box3 */}
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between"
      >
        {/* <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          // alignItems="center"
        >
          <Box
            width={{ base: '100%', sm: '85%' }}
            zIndex="2"
            marginLeft={{ base: '0', sm: '5%' }}
            marginTop="5%"
          >
            <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
              <Image
                borderRadius="lg"
                src={Image3}
                alt="닥터 사이먼"
                objectFit="contain"
              />
            </Link>
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box backgroundSize="20px 20px" opacity="0.4" height="100%" />
          </Box>
        </Box> */}
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}
        >
          <Link textDecoration="none" _hover={{ textDecoration: 'none' }} display={'flex'} justifyContent={'center'}>
            <Image
              borderRadius="lg"
              src={Image3}
              alt="닥터 사이먼"
              objectFit="contain"
              width={WIDTHS}
            />
          </Link>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            marginBottom={paragraphSpacing}
            // 들여쓰기
            textIndent={paragraphIndent}
            // 줄간격
            lineHeight={paragraphLineHeight}
          >
            <Text fontWeight={'bold'} display={'inline'}>
              충동성은 마음 건강에도 큰 영향을 미칩니다.
            </Text>{' '}
            인터넷 게임 중독에 빠진 아이들에게선 충동성이 나타내는 경향이 있고,
            주의력결핍 및 과잉행동 장애(ADHD)나 불안, 우울을 겪을 수도 있습니다.
            흔히, 인터넷게임 중독에 걸리는 주원인 중의 하나도 아이의
            충동성이라고 합니다. 인터넷이나 게임을 많이 해서 충동적으로 된다기
            보다는, 충동적인 아이가 인터넷게임을 많이 하게 되는 위험인자라는
            것입니다. 즉, 자기 행동을 통제하기 어렵기 때문에 게임을 많이 한다는
            것이지요. 그러다 보면 게임 자체가 자극적이고, 심지어 보상을 주는
            활동까지 하게 되면서 뇌에서 분비되는 도파민으로 인해 쾌감을 느끼고,
            이에 따라 다시 게임을 계속하고 싶은 동기가 유발되는 것입니다. 이를
            다시 말해서, 인터넷게임을 막는 게 능사는 아니라는 것입니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            marginBottom={paragraphSpacing}
            // 들여쓰기
            textIndent={paragraphIndent}
            // 줄간격
            lineHeight={paragraphLineHeight}
          >
            <Text fontWeight={'bold'} display={'inline'}>
              충동성이 심해지면, 충동조절장애란 병을 얻을 수 있습니다.
            </Text>{' '}
            보통 분노조절장애라고 부르는 것을 정신의학자나 임상심리학자는
            공식적으로 충동조절장애라고 부릅니다. 충동성으로 어려움을 겪는
            청소년은 감정 조절이 잘 안되어 행동 통제나 스트레스 관리에 어려움을
            겪을 수 있습니다. 그래서 약물이나 술을 마시게 되기도 하고,
            반사회적인 행동이나 범죄를 저지를 수도 있습니다. 방화, 폭행, 절도 등
            자신뿐만 아니라 타인에게 해가 될 만한 반사회적인 충동을 조절하지
            못하고 행동으로 옮기는 경우가 반복될 수 있습니다. 그래서 사회 문제가
            되고 우리 부모에게 충격을 주는 비행, 자해, 자살, 반사회적 범죄가
            충동장애와 연결된다는 연구들이 많이 있습니다. 충동조절장애는 심각한
            병입니다. 보다 자세한 사항은 서울대학교병원 홈페이지를 참고해
            주세요.
          </Text>
          <Link
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            href="http://www.snuh.org/health/nMedInfo/nView.do?category=DIS&medid=AA000771"
            isExternal
            my={'4'}
          >
            서울대학교병원
            <ExternalLinkIcon mx="2px" />
          </Link>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            marginBottom={paragraphSpacing}
            // 들여쓰기
            textIndent={paragraphIndent}
            // 줄간격
            lineHeight={paragraphLineHeight}
          >
            <Text fontWeight={'bold'} display={'inline'}>
              그러나 충동성이 무조건 나쁜 것은 아닙니다.
            </Text>{' '}
            적절한 수준의 충동은 창의적인 아이디어나 자발성과 관련되어 있고,
            어렵고 위험해도 새로운 시도를 하는 능력, 특히 주저 없이 도전하는
            능력과 관련이 있습니다. 그래서 이러한 성향은 긍정적인 경험과 성장의
            기회로 이어질 수 있습니다. 아울러, 긴급 상황이나 빠르게 변화하는
            환경에 맞추어 결정하고 행동해야 할 때는 충동적 성향이 도움이 될 수
            있습니다. 즉, 적절한 충동성은 자발적이고 창의적인 사고가 중요한
            과목에서 유익할 수 있습니다. 예를 들어, 수학같이 꼼꼼한 분석력과
            정확성, 완성도가 필요한 과목에서 충동성은 어려움을 초래할 수 있지만,
            미술 같은 과목에서 새로운 아이디어나 창조적인 결실로 이어질 수
            있습니다. 또한 충동적인 학생은 위험을 감수하고 새로운 접근 방식을
            시도할 가능성이 높기 때문에, 새로운 프로젝트를 시도하는 등 학습과
            성취에 도움이 될 수 있습니다.
          </Text>
          <Link textDecoration="none" _hover={{ textDecoration: 'none' }} display={'flex'} justifyContent={'center'}>
            <Image
              borderRadius="lg"
              src={Image4}
              alt="닥터 사이먼"
              objectFit="contain"
              width={WIDTHS}
            />
          </Link>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            marginBottom={paragraphSpacing}
            // 들여쓰기
            textIndent={paragraphIndent}
            // 줄간격
            lineHeight={paragraphLineHeight}
          >
            <Text fontWeight={'bold'} display={'inline'}>
              과유불급. 중요한 것은 결국 적절한 충동 조절 역량을 기르는
              것입니다.
            </Text>{' '}
            충동성의 부정적인 역할처럼, 긍정적인 측면도 적절히 관리하지 않으면
            부정적인 결과를 초래할 수 있습니다. 예를 들어, 잠재적인 결과를
            고려하지 않고 너무 많은 위험을 감수하면 결국 해로운 상황으로 이어질
            수 있습니다. 마찬가지로 충동적인 의사 결정은 때때로 잘못된 선택이나
            후회스러운 행동으로 이어질 수 있습니다. 따라서 충동성과 신중한 의사
            결정 사이의 균형을 찾는 것이 중요합니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
          >
            혹시,{' '}
            <Text fontWeight={'bold'} display={'inline'}>
              아이가 조용하거나 내성적이라고 해서 충동적이지 않다고 생각해서는
              안 됩니다.
            </Text>{' '}
            오히려 잘 살펴야 합니다. 아이가 조용하면, 차분하고 절제 있다고
            생각하는 경향이 있지만, 아이의 성격 유형이나 외향성/내향성 수준에
            따라 충동성이 결정되지는 않습니다. 오히려 안으로 쌓인 에너지가
            폭발할 수 있습니다. 따라서 단순히 특정 성격 유형이 충동성에 더
            취약하다고 가정하기보다는, 아이의 행동과 인지 과정을 기반으로
            충동성을 평가할 필요가 있습니다.
          </Text>
        </Box>
      </Box>
    </Container>
  );
};

export default BlogPageTwo;
