import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Sidebar from '@components/Layout/Sidebar';
import styles from './index.module.scss';
import { setSidebarState, getSchoolPromise } from '@store/actions';
import { getAccountInfoByAccountId, setToken } from '@api';
import {
  Chart as ChartJS,
  LinearScale, //y
  CategoryScale, //x
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  RadialLinearScale,
} from 'chart.js';
import {
  GetParentStudentJointPromise,
  getAccountInfoByAccountIdPromise,
} from '@app/store/actions';
import { Box, Button, Container, Text } from '@chakra-ui/react';
import WithSubnavigation from '@app/components/Layout/Navbar';
import BlogPageZero from './BlogPageZero';
import BlogPageOne from './BlogPageOne';
import BlogPageTwo from './BlogPageTwo';
import BlogPageThree from './BlogPageThree';
import BlogPageFour from './BlogPageFour';
import BlogPageFive from './BlogPageFive';

ChartJS.register(
  RadialLinearScale,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
);

const pages = [
  'BlogPageZero',
  'BlogPageOne',
  'BlogPageTwo',
  'BlogPageThree',
  'BlogPageFour',
  // 'BlogPageFive',
];

const Blog = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const control = useSelector((state) => state.control);
  const user = useSelector((state) => state.user);
  const parentStudents = useSelector((state) => state.user.parentStudents);
  const [currentPage, setCurrentPage] = useState('BlogPageZero');
  const existingStudentIds = parentStudents?.map(
    (studentId) => studentId.existingStudentId
  );

  useEffect(() => {
    dispatch(getAccountInfoByAccountIdPromise(user.accountId));
  }, [user]);

  useEffect(() => {
    const schoolId = user.schoolId;
    if (schoolId == null) {
      //link to focuspang school
      dispatch(getSchoolPromise(1424));
    }
    dispatch(getSchoolPromise(schoolId));

    if (user) {
      dispatch(GetParentStudentJointPromise(user.clientId)).then((res) =>
        console.log('res', res)
      );
    }
  }, []);

  useEffect(() => {
    if (user.token !== undefined || user.token !== null) {
      setToken(user.token).then(() => {
        try {
          console.log('set token to program...');
          window.cAPI.setToken(user.token);
        } catch (error) {}
      });
    }
    if (user.state === 'REGISTERED_APPROVED') {
      if (control.sidebarState === false) dispatch(setSidebarState());
    } else {
      //dispatch(setSidebarState(false));
    }
  }, [user, control]);

  const handlePageChange = (pageIndex) => {
    setCurrentPage(pages[pageIndex]);
  };

  return (
    <>
      <WithSubnavigation />
      <Container
        maxW={'100%'}
        minH={'100vh'}
        bg={'white'}
        display={'flex'}
        flexDirection={'column'}
        // justifyContent={'center'}
        // alignItems={'center'}
        padding={'0'}
        margin={'0'}
      >
        <Box position={'relative'}>
          {currentPage === 'BlogPageZero' && <BlogPageZero />}
          {currentPage === 'BlogPageOne' && <BlogPageOne />}
          {currentPage === 'BlogPageTwo' && <BlogPageTwo />}
          {currentPage === 'BlogPageThree' && <BlogPageThree />}
          {currentPage === 'BlogPageFour' && <BlogPageFour />}
          {/* {currentPage === 'BlogPageFive' && <BlogPageFive />} */}
        </Box>
        <Box
          position={'fixed'}
          bottom={'0'}
          left={'0'}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          width={'100%'}
          height={'50px'}
          bg={'white'}
          zIndex={'100'}
        >
          <Button
            onClick={() => {
              handlePageChange(Math.max(pages.indexOf(currentPage) - 1, 0));
              window.scrollTo(0, 0);
            }}
            disabled={pages.indexOf(currentPage) === 0}
            mr={'10px'}
          >
            &lt;
          </Button>
          {pages.map((page, index) => (
            <Box
              key={index}
              onClick={() => {
                handlePageChange(index);
                window.scrollTo(0, 0);
              }}
              width={'50px'}
              height={'50px'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              cursor={'pointer'}
              bg={currentPage === page ? 'gray.200' : 'white'}
            >
              <Text>{index + 1}</Text>
            </Box>
          ))}
          <Button
            onClick={() => {
              handlePageChange(
                Math.min(pages.indexOf(currentPage) + 1, pages.length - 1)
              );
              window.scrollTo(0, 0);
            }}
            disabled={pages.indexOf(currentPage) === pages.length - 1}
            ml={'10px'}
          >
            &gt;
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default Blog;
