import { all, fork } from 'redux-saga/effects';

import userSaga from './user/saga';
import schoolSaga from './school/saga';
import accountSaga from './accounts/saga';
import clientSaga from './clients/saga';
import groupSaga from './groups/saga';
import membershipSaga from './membership/saga';

export default function* rootSaga() {
  yield all([
    fork(userSaga),
    fork(clientSaga),
    fork(membershipSaga),
    fork(groupSaga),
    fork(schoolSaga),
    fork(accountSaga),
  ]);
}
