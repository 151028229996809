

export const storeStorageData = async (key, value) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.log(e);
  }
}

export const getStorageData = async (key) => {
  try {
    const item = sessionStorage.getItem(key);
    return item;
  } catch(e) {
    console.log(e);
  }
}
