import {
  PURGE_CLIENT,

  GET_CLIENTS_SUCCESS,
  
  CREATE_TEACHER_ADMIN_SUCCESS,
  CREATE_TEACHER_ADMIN_FAIL,

  CREATE_STUDENT_ADMIN_SUCCESS,
  CREATE_STUDENT_ADMIN_FAIL,

  CREATE_MANAGER_TEACHER_ADMIN_SUCCESS,
  CREATE_MANAGER_TEACHER_ADMIN_FAIL,

  GET_ALL_TEACHERS_ADMIN_SUCCESS,
  GET_ALL_TEACHERS_ADMIN_FAIL,

  GET_ALL_MANAGER_TEACHERS_ADMIN_SUCCESS,
  GET_ALL_MANAGER_TEACHERS_ADMIN_FAIL,

  GET_ALL_STUDENTS_ADMIN_SUCCESS,
  GET_ALL_STUDENTS_ADMIN_FAIL,

  GET_ALL_STUDENTS_INDEX_SUCCESS,
  GET_ALL_STUDENTS_INDEX_FAIL,

  FIND_TEACHERS_ADMIN_SUCCESS,
  FIND_TEACHERS_ADMIN_FAIL,

  UPDATE_TEACHER_INFO_ADMIN_SUCCESS,
  UPDATE_TEACHER_INFO_ADMIN_FAIL,

  DELETE_CLIENT_ADMIN_SUCCESS,
  DELETE_CLIENT_ADMIN_FAIL,

  STOP_CLIENT_ADMIN_SUCCESS,
  STOP_CLIENT_ADMIN_FAIL,

  REUSE_CLIENT_ADMIN_SUCCESS,
  REUSE_CLIENT_ADMIN_FAIL,


} from './actionTypes';
import * as api from '@api';
import { createPromiseAction } from '@adobe/redux-saga-promise';

export const purgeClient = () => ({
  type: PURGE_CLIENT,
});

//CREATE_STUDENT_ADMIN
export const createStudentAdminPromise = createPromiseAction(
  "CREATE_STUDENT_ADMIN_PROMISE"
);

export const createStudentAdminSuccess = (profileBackend) => {
  return {
    type: CREATE_STUDENT_ADMIN_SUCCESS,
    profile: {
      ...api.unpackClient(profileBackend),
      signedIn: true,
    },
  };
};

export const createStudentAdminFail = (error) => ({
  type: CREATE_STUDENT_ADMIN_FAIL,
  error: error,
});

//CREATE_TEACHER_ADMIN
export const createTeacherAdminPromise = createPromiseAction(
  "CREATE_TEACHER_ADMIN_PROMISE"
);

export const createTeacherAdminSuccess = (profileBackend) => {
  return {
    type: CREATE_TEACHER_ADMIN_SUCCESS,
    profile: {
      ...api.unpackClient(profileBackend),
      signedIn: true,
    },
  };
};

export const createTeacherAdminFail = (error) => ({
  type: CREATE_TEACHER_ADMIN_FAIL,
  error: error,
});

//GET_ALL_TEACHER_ADMIN
export const getAllTeachersAdminPromise = createPromiseAction(
  "GET_ALL_TEACHERS_ADMIN_PROMISE"
);

export const getAllTeachersAdminSuccess = (clientData) => { //이걸로 선생님, 관리자
  let byId = {};
  const clientData_T= clientData.filter(x => x.client.role=="TEACHER"||x.client.role=="ADMIN" && x.account.state!='DELETED' && x.client.state!='DELETED' ) ;
  clientData_T.forEach((clientData1) => {
    byId[clientData1.client.id] = api.unpackClient(clientData1);
  });
  const only_teacher= clientData.filter(x => x.client.role=="TEACHER" && x.account.state!='DELETED' && x.client.state!='DELETED' ) ;
  only_teacher.forEach((clientData1) => {
    byId[clientData1.client.id] = api.unpackClient(clientData1);
  });

  const allIds = clientData_T.map((clientData1) => clientData1.client.accountId);
  const allTeacher = clientData_T.map((clientData1) => clientData1);
  const onlyTeacher = only_teacher.map((clientData1) => clientData1);
  return {
    type: GET_ALL_TEACHERS_ADMIN_SUCCESS,
    byId: byId,
    allIds: allIds,
    allTeacher: allTeacher,
    onlyTeacher: onlyTeacher,
  };
};

export const getAllTeachersAdminFail = (error) => ({
  type: GET_ALL_TEACHERS_ADMIN_FAIL,
  error: error,
});

//GET_ALL_MANAGER_TEACHER_ADMIN
export const getAllManagerTeachersAdminPromise = createPromiseAction(
  "GET_ALL_MANAGER_TEACHERS_ADMIN_PROMISE"
);

export const getAllManagerTeachersAdminSuccess = (clientData) => {
  let byId = {};
  
  const clientData_T= clientData.filter(
    (x =>  x.account.state!='DELETED' && x.client.state!='DELETED' && 
    ( x.client.role=="admin" || x.client.role=="ADMIN" ) 
    ) );

  clientData_T.forEach((clientData1) => {
    byId[clientData1.client.id] = api.unpackClient(clientData1);
  });

  const allIds = clientData_T.map((clientData1) => clientData1.client.accountId);

  const allManagerTeacher = clientData_T.map((clientData1) => clientData1);
  return {
    type: GET_ALL_MANAGER_TEACHERS_ADMIN_SUCCESS,
    byId: byId,
    allIds: allIds,
    allManagerTeacher: allManagerTeacher,
  };
};

export const getAllManagerTeachersAdminFail = (error) => ({
  type: GET_ALL_MANAGER_TEACHERS_ADMIN_FAIL,
  error: error,
});

//GET_ALL_STUDENT_ADMIN
export const getAllStudentsAdminPromise = createPromiseAction(
  "GET_ALL_STUDENTS_ADMIN_PROMISE"
);

export const getAllStudentsAdminSuccess = (clientData) => { //이걸로 학생
  // return {
  //   type: GET_ALL_STUDENTS_ADMIN_SUCCESS,
  //   profile: {
  //     ...api.unpackClient(profileBackend),
  //     signedIn: true,
  //   },
  // };
  let byId = {};
  const clientData_student = clientData.filter(data => data.client.role == "STUDENT" && data.account.state != "DELETE" && data.client.state != "DELETE");
  clientData_student.forEach((studentData) => {
    byId[studentData.client.id] = api.unpackClient(studentData);
  });
  const allIds = clientData_student.map((studentData) => studentData.client.accountId);
  const allStudent = clientData_student.map((studentData) => studentData);
  // clientData.forEach((clientData) => {
  //   byId[clientData.id] = api.unpackClient(clientData);
  // });
  // const allIds = clientData.map((clientData) => clientData.id);
  // const allStudent = clientData.map((clientData) => clientData);
  return {
    type: GET_ALL_STUDENTS_ADMIN_SUCCESS,
    byId: byId,
    allIds: allIds,
    allStudent: allStudent,
  };
};

export const getAllStudentsAdminFail = (error) => ({
  type: GET_ALL_STUDENTS_ADMIN_FAIL,
  error: error,
});

//GET_ALL_STUDENTS_INDEX
export const getAllStudentsIndexPromise = createPromiseAction(
  "GET_ALL_STUDENTS_INDEX_PROMISE"
);

export const getAllStudentsIndexSuccess = (allStudentData) => {
  const allStudent = allStudentData.accountClients;
  let byId = {};
  allStudent.forEach((studentData) => {
    byId[studentData.client.clientId] = api.unpackClient(studentData)
  });
  const allIds = allStudent.map((studentData) => studentData.account.accountId);
  const studentIndex = allStudent.map((studentData) => studentData);
  return {
    type: GET_ALL_STUDENTS_INDEX_SUCCESS,
    byId: byId,
    allIds: allIds,
    studentIndex: studentIndex,
  };
};

export const getAllStudentsIndexFail = (error) => ({
  type: GET_ALL_STUDENTS_INDEX_FAIL,
  error: error,
});

//FIND_TEACHERS_ADMIN
export const findTeachersAdminPromise = createPromiseAction(
  "FIND_TEACHERS_ADMIN_PROMISE"
);

export const findTeachersAdminSuccess = (profileBackend) => {
  return {
    type: FIND_TEACHERS_ADMIN_SUCCESS,
    profile: {
      ...api.unpackClient(profileBackend),
      signedIn: true,
    },
  };
};

export const findTeachersAdminFail = (error) => ({
  type: FIND_TEACHERS_ADMIN_FAIL,
  error: error,
});

//// UPDATE TEACHER INFO ADMIN
export const updateTeacherInfoAdminPromise = createPromiseAction("UPDATE_TEACHER_INFO_ADMIN_PROMISE");

export const updateTeacherInfoAdminSuccess = (userInfo) => {
  return {
    type: UPDATE_TEACHER_INFO_ADMIN_SUCCESS,
    profile: {
      ...api.unpackClient(userInfo),
    },
  };
};

export const updateTeacherInfoAdminFail = (error) => ({
  type: UPDATE_TEACHER_INFO_ADMIN_FAIL,
  error: error,
});

//// DELETE CLIENT ADMIN
export const deleteClientAdminPromise = createPromiseAction("DELETE_CLIENT_ADMIN_PROMISE");

export const deleteClientAdminSuccess = (userInfo) => {
  return {
    type: DELETE_CLIENT_ADMIN_SUCCESS,
    profile: {
      ...api.unpackClient(userInfo),
    },
  };
};

export const deleteClientAdminFail = (error) => ({
  type: DELETE_CLIENT_ADMIN_FAIL,
  error: error,
});

//// STOP CLIENT ADMIN
export const stopClientAdminPromise = createPromiseAction("STOP_CLIENT_ADMIN_PROMISE");

export const stopClientAdminSuccess = (userInfo) => {
  return {
    type: STOP_CLIENT_ADMIN_SUCCESS,
    profile: {
      ...api.unpackClient(userInfo),
    },
  };
};

export const stopClientAdminFail = (error) => ({
  type: STOP_CLIENT_ADMIN_FAIL,
  error: error,
});

//// REUSE CLIENT ADMIN
export const reuseClientAdminPromise = createPromiseAction("REUSE_CLIENT_ADMIN_PROMISE");

export const reuseClientAdminSuccess = (userInfo) => {
  return {
    type: REUSE_CLIENT_ADMIN_SUCCESS,
    profile: {
      ...api.unpackClient(userInfo),
    },
  };
};

export const reuseClientAdminFail = (error) => ({
  type: REUSE_CLIENT_ADMIN_FAIL,
  error: error,
});

//CREATE_MANAGER_TEACHER_ADMIN
export const createManagerTeacherAdminPromise = createPromiseAction(
  "CREATE_MANAGER_TEACHER_ADMIN_PROMISE"
);

export const createManagerTeacherAdminSuccess = (profileBackend) => {
  return {
    type: CREATE_MANAGER_TEACHER_ADMIN_SUCCESS,
    profile: {
      ...api.unpackClient(profileBackend),
      signedIn: true,
    },
  };
};

export const createManagerTeacherAdminFail = (error) => ({
  type: CREATE_MANAGER_TEACHER_ADMIN_FAIL,
  error: error,
});

export const getClientsByMembershipSuccess = clientMembershipsData => {
  let byId = {};
  clientMembershipsData.forEach((clientMembershipData) => {
    byId[clientMembershipData.client.clientId] = api.unpackClient(clientMembershipData.client)});
    
  const allIds = clientMembershipsData
    .map((clientMembershipsData) => clientMembershipsData.client.clientId);
  
  return {
    type: GET_CLIENTS_SUCCESS,
    byId: byId,
    allIds: allIds,
  }
}