import { call, put, takeLeading } from "redux-saga/effects";
import {
  PURGE_ALL,
  SIGN_OUT,
  RESTORE_USER,
} from "./actionTypes";
import {
  teacherSignUpPromise,
  signInPromise,
  signInSuccess,
  signInFail,
  signOut,
  changePasswordPromise,
  changePasswordError,
  purgeClient,
  purgeGroup,
  purgeUser,

  teacherSignUpSuccess,
  teacherSignUpFail,
  purgeSchool,
  purgeMembership,
  purgeControl,

  signInByEmailPromise,
  signInByEmailSuccess,
  signInByEmailFail,

  GetParentStudentJointPromise,
  GetParentStudentJointSuccess,
  GetParentStudentJointFail,

  updateAccountInfoPromise,
  updateAccountInfoSuccess,
  updateAccountInfoFail,

  registerDashboardTeacherPromise,
  registerDashboardTeacherSuccess,
  registerDashboardTeacherFail,

  registerDashboardStudentPromise,
  registerDashboardStudentSuccess,
  registerDashboardStudentFail,

  registerDashboardParentPromise, 
  registerDashboardParentSuccess,
  registerDashboardParentFail, 
} from "@store/actions";
import * as api from "@api";
import { implementPromiseAction } from "@adobe/redux-saga-promise";

function* purgeAllHandler() {
  try {
    console.log("Purging all redux...");
    yield put(signOut());
    yield put(purgeUser());
    yield put(purgeClient());
    yield put(purgeGroup());
    yield put(purgeControl());
    yield put(purgeSchool());
    yield put(purgeMembership());
  } catch (error) {
    console.error(error);
  }
}

function* signInPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const signInData = action.payload;
      const response = yield call(api.signIn, signInData);
      if(response.data.client.state!='REGISTERED_APPROVED') {
        alert(signInData.email + '은 정지된 관리자계정입니다');
        yield put(signInFail(response));
        return;
      }
      const userData = api.unpackUser(response.data.account, response.data.client);
      yield call(api.storeStorageData, "@user", userData);
      yield call (api.setToken, response.data.jwt.token);
      yield put(signInSuccess(userData));
      return yield userData;
    } catch (error) {
      yield put(signInFail(error));
    }
  });
}

function* signInByEmailPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const signInData = action.payload;
      const response = yield call (api.signInByEmail, signInData);
      const userData = api.unpackUser(response.data.account, response.data.client);
      yield call(api.storeStorageData, "@user", userData);
      yield call (api.setToken, response.data.jwt.token);
      yield put(signInByEmailSuccess(userData));
      return yield userData;
    } catch (error) {
      yield put(signInByEmailFail(error));
    }
  })
}

function* GetParentStudentJointPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response  = yield call(api.GetParentStudentJoint, action.payload)
      // console.log('response',response.data)
      yield put(GetParentStudentJointSuccess(response.data.parentStudents));
      return yield response.data;
    } catch (error) {
      yield put(GetParentStudentJointFail(error))
    }
  })
}

function* updateAccountInfoPromiseHandler(action) {
  yield call(implementPromiseAction, action, function* () {
    try {
      const updatedUser = action.payload;
      const response = yield call(api.updateAccountInfo, updatedUser);
      const userData = api.unpackUser(
        response.data.account,
        response.data.client
      );
      yield call(api.storeStorageData, '@user', userData);
      yield put(updateAccountInfoSuccess(userData));
      return yield userData;
    } catch (error) {
      yield put(updateAccountInfoFail(error));
    }
  });
}

function* signOutHandler() {
  try {
    // todo: clear redux
    yield call(api.resetToken);
  } catch (error) {
    console.error(error);
  }
}

function* changePwPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.findPasswordClient, action.payload);
      return response.data;
    } catch (error) {
      yield put(changePasswordError(error));
    }
  });
}

function* restoreUserHandler({ profile: profileData }) {
  try {
    console.log("Restoring from session...");
    // todo: this packs then immediately unpacks profileData.
    // todo: should fix this
    yield call(api.setToken, sessionStorage.getItem("token"));
  } catch (error) {
    console.error(error);
  }
}

function* teacherSignUpHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      console.log(action.payload);
      const newClient = action.payload;
      const response = yield call(api.registerTeacher, newClient);
      // const userInfo = api.unpackClient(response.data);
      // yield call(api.setToken, userInfo.token);
      // yield call(api.storeStorageData, "@user", userInfo);
      yield put(teacherSignUpSuccess(api.unpackUser(response.data)));
      return yield response.data;
    } catch (error) {
      yield put(teacherSignUpFail(error));
    }
  });
}

function* registerDashboardTeacherPromiseHandler(action) {
  yield call(implementPromiseAction, action, function* () {
    try {
      const newClient = action.payload;
      const response = yield call(api.createTeacherAccount, newClient);
      const userData = api.unpackUser(
        response.data.account,
        response.data.client
      );
      console.log('user saga', response.data)
      yield call(api.setToken, response.data.jwt.token);
      yield put(registerDashboardTeacherSuccess(userData));
      return yield userData;
    } catch (error) {
      yield put(registerDashboardTeacherFail(error));
    }
  });
}

function* registerDashboardStudentPromiseHandler(action) {
  yield call(implementPromiseAction, action, function* () {
    try {
      const newClient = action.payload;
      const response = yield call(api.createStudentAccount, newClient);
      const userData = api.unpackUser(
        response.data.account,
        response.data.client
      );
      console.log('user saga', response.data)
      yield call(api.setToken, response.data.jwt.token);
      yield put(registerDashboardStudentSuccess(userData));
      return yield userData;
    } catch (error) {
      yield put(registerDashboardStudentFail(error));
    }
  });
}

function* registerDashboardParentPromiseHandler(action) {
  yield call(implementPromiseAction, action, function* () {
    try {
      const newClient = action.payload;
      const response = yield call(api.createParentAccount, newClient);
      const userData = api.unpackUser(
        response.data.account,
        response.data.client
      );
      console.log('user saga', response.data)
      yield call(api.setToken, response.data.jwt.token);
      yield put(registerDashboardParentSuccess(userData));
      return yield userData;
    } catch (error) {
      yield put(registerDashboardParentFail(error));
    }
  });
}

function* userSaga() {
  yield takeLeading(PURGE_ALL, purgeAllHandler);
  yield takeLeading(teacherSignUpPromise, teacherSignUpHandler);
  yield takeLeading(signInPromise, signInPromiseHandler);
  yield takeLeading(signInByEmailPromise, signInByEmailPromiseHandler);
  yield takeLeading(GetParentStudentJointPromise, GetParentStudentJointPromiseHandler);
  yield takeLeading(updateAccountInfoPromise, updateAccountInfoPromiseHandler);
  yield takeLeading(changePasswordPromise, changePwPromiseHandler);
  yield takeLeading(SIGN_OUT, signOutHandler);
  yield takeLeading(RESTORE_USER, restoreUserHandler);
  yield takeLeading(registerDashboardTeacherPromise, registerDashboardTeacherPromiseHandler);
  yield takeLeading(registerDashboardStudentPromise, registerDashboardStudentPromiseHandler);
  yield takeLeading(registerDashboardParentPromise, registerDashboardParentPromiseHandler);
}

export default userSaga;