import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';

const SummaryText = ({
  commentsData,
  paragraphIndent,
  paragraphLineHeight,
}) => {
  return (
    <Box
      marginTop="6"
      p={4}
      bg={useColorModeValue('gray.100')}
      borderRadius={16}
      border={useColorModeValue('2px solid #000000', '1px solid #2d3748')}
    >
      <Box display="flex" mb={2}>
        <BookOutlinedIcon
          style={{
            marginRight: '10px',
          }}
        />
        <Text>닥터 사이먼의 요약</Text>
      </Box>
      <Text
        as="p"
        color={useColorModeValue('gray.700', 'gray.200')}
        fontSize="lg"
        fontFamily={'Noto Serif KR'}
        textIndent={paragraphIndent}
        lineHeight={paragraphLineHeight}
      >
        {commentsData.summary}
      </Text>
    </Box>
  );
};

export default SummaryText;
