import { makeFreshAllIds } from "../helpers";
import {
  PURGE_CLIENT,

  GET_CLIENTS_SUCCESS,

  CREATE_TEACHER_ADMIN_SUCCESS,
  CREATE_TEACHER_ADMIN_FAIL,

  GET_ALL_TEACHERS_ADMIN_SUCCESS,
  GET_ALL_TEACHERS_ADMIN_FAIL,

  GET_ALL_MANAGER_TEACHERS_ADMIN_SUCCESS,
  GET_ALL_MANAGER_TEACHERS_ADMIN_FAIL,

  GET_ALL_STUDENTS_ADMIN_SUCCESS,
  GET_ALL_STUDENTS_ADMIN_FAIL,

  GET_ALL_STUDENTS_INDEX_SUCCESS,
  GET_ALL_STUDENTS_INDEX_FAIL,

  FIND_TEACHERS_ADMIN_SUCCESS,
  FIND_TEACHERS_ADMIN_FAIL,

  UPDATE_TEACHER_INFO_ADMIN_SUCCESS,
  UPDATE_TEACHER_INFO_ADMIN_FAIL,

  DELETE_CLIENT_ADMIN_SUCCESS,
  DELETE_CLIENT_ADMIN_FAIL,

  STOP_CLIENT_ADMIN_SUCCESS,
  STOP_CLIENT_ADMIN_FAIL,

  REUSE_CLIENT_ADMIN_SUCCESS,
  REUSE_CLIENT_ADMIN_FAIL,

  CREATE_MANAGER_TEACHER_ADMIN_SUCCESS,
  CREATE_MANAGER_TEACHER_ADMIN_FAIL,
} from "./actionTypes";

const initialState = {
  byId: {},
  allIds: [],
  byIdTemp: {},
  allIdsTemp: [],
  allTeacher: [], // teacher + manager
  allManagerTeacher: [], //manager
  onlyTeacher: [], //teacher
  allStudent: [], //student
  studentIndex: [], //student index
};

const clients = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_CLIENT:
      // console.log('purge clients');
      return initialState;

    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, ...action.byId },
        allIds: makeFreshAllIds(state.allIds, action.allIds),
      };

    case GET_ALL_TEACHERS_ADMIN_SUCCESS:
      return {
        ...state,
        // byId: { ...state.byId, [action.teachersProfile.id]: action.teachersProfile },
        // allIds: makeFreshAllIds(state.allIds, [action.teachersProfile.id]),

        byId: { ...state.byId, ...action.byId },
        allTeacher: (state.allTeacher, action.allTeacher),
        allIds: makeFreshAllIds(state.allIds, action.allIds),
        onlyTeacher: (state.onlyTeacher, action.onlyTeacher),
      };
    case GET_ALL_MANAGER_TEACHERS_ADMIN_SUCCESS:
      return {
        ...state,
        // byId: { ...state.byId, [action.teachersProfile.id]: action.teachersProfile },
        // allIds: makeFreshAllIds(state.allIds, [action.teachersProfile.id]),

        byId: { ...state.byId, ...action.byId },
        allManagerTeacher: (state.allManagerTeacher, action.allManagerTeacher),
        allIds: makeFreshAllIds(state.allIds, action.allIds),
      };
    case GET_ALL_STUDENTS_ADMIN_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, ...action.byId },
        allStudent: (state.allStudent, action.allStudent),
        allIds: makeFreshAllIds(state.allIds, action.allIds),
      };
    case GET_ALL_STUDENTS_INDEX_SUCCESS:
      return {
        ...state,
        byId: {...state.byId, ...action.byId},
        allIds: makeFreshAllIds(state.allIds, action.allIds),
        studentIndex: (state.studentIndex, action.studentIndex),
      }
    case FIND_TEACHERS_ADMIN_SUCCESS:
      return {
        ...state,
        // byId: { ...state.byId, [action.teachersProfile.id]: action.teachersProfile },
        // allIds: makeFreshAllIds(state.allIds, [action.teachersProfile.id]),
        byId: { ...state.byId, ...action.byId },
        allIds: makeFreshAllIds(state.allIds, action.allIds),
      };

    case CREATE_TEACHER_ADMIN_SUCCESS:
    case CREATE_MANAGER_TEACHER_ADMIN_SUCCESS:
    case UPDATE_TEACHER_INFO_ADMIN_SUCCESS:
    case DELETE_CLIENT_ADMIN_SUCCESS:
    case STOP_CLIENT_ADMIN_SUCCESS:
    case REUSE_CLIENT_ADMIN_SUCCESS:
      return { ...state, ...action.profile };

    // todo: handle errors
    case CREATE_TEACHER_ADMIN_FAIL:
    case GET_ALL_TEACHERS_ADMIN_FAIL:
    case GET_ALL_MANAGER_TEACHERS_ADMIN_FAIL:
    case GET_ALL_STUDENTS_ADMIN_FAIL:
    case FIND_TEACHERS_ADMIN_FAIL:
    case UPDATE_TEACHER_INFO_ADMIN_FAIL:
    case DELETE_CLIENT_ADMIN_FAIL:
    case REUSE_CLIENT_ADMIN_FAIL:
    case STOP_CLIENT_ADMIN_FAIL:
    case CREATE_MANAGER_TEACHER_ADMIN_FAIL:
      console.error(action.payload);
      return state;
    default:
      break;
  }
  return state;
};

export default clients;