import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const Home = (props) => {
  const history = useHistory();

  useEffect(() => {
    history.replace('/', null);
    props.history.replace('/dashboard');
  }, []);

  return <div />;
};

export default Home;
