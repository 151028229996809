import React from 'react';
import { Container } from '@chakra-ui/react';
import ReactApexChart from 'react-apexcharts';

class Barchart extends React.Component {
  constructor(props) {
    super(props);

    const {
      impulsivenessScore,
      conceptUnderstandingScore,
      learningAttitudeScore,
      spacingScore,
      achievementScore,
    } = props.data;

    const {
      avgImpulsivenessScore,
      avgConceptUnderstandingScore,
      avgLearningAttitudeScore,
      avgSpacingScore,
      avgAchievementScore,
      topLevelAvgImpulsivenessScore,
      topLevelAvgConceptUnderstandingScore,
      topLevelAvgLearningAttitudeScore,
      topLevelAvgSpacingScore,
      topLevelAvgAchievementScore,
    } = props.averageAnalysisData;

    const { studentName } = props.niaStudentInformation;

    this.state = {
      series: [
        {
          name: studentName,
          data: [
            impulsivenessScore,
            conceptUnderstandingScore,
            learningAttitudeScore,
            spacingScore,
            achievementScore,
          ],
        },
        {
          name: '최상위 1등급',
          data: [
            +topLevelAvgImpulsivenessScore.toFixed(0),
            +topLevelAvgConceptUnderstandingScore.toFixed(0),
            +topLevelAvgLearningAttitudeScore.toFixed(0),
            +topLevelAvgSpacingScore.toFixed(0),
            +topLevelAvgAchievementScore.toFixed(0),
          ],
        },
        {
          name: '평균',
          data: [
            +avgImpulsivenessScore.toFixed(0),
            +avgConceptUnderstandingScore.toFixed(0),
            +avgLearningAttitudeScore.toFixed(0),
            +avgSpacingScore.toFixed(0),
            +avgAchievementScore.toFixed(0),
          ],
        },
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },
        //다운로드 기능 제거
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: ['#FFB946', '#3182CE', '#63B3ED'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: ['충동성', '개념이해', '학습태도', '띄어쓰기', '성취'],
        },
        //y축 라벨 제거
        yaxis: {
          labels: {
            show: false,
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {},
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      const {
        impulsivenessScore,
        conceptUnderstandingScore,
        learningAttitudeScore,
        spacingScore,
        achievementScore,
      } = this.props.data;

      const {
        avgImpulsivenessScore,
        avgConceptUnderstandingScore,
        avgLearningAttitudeScore,
        avgSpacingScore,
        avgAchievementScore,
        topLevelAvgImpulsivenessScore,
        topLevelAvgConceptUnderstandingScore,
        topLevelAvgLearningAttitudeScore,
        topLevelAvgSpacingScore,
        topLevelAvgAchievementScore,
      } = this.props.averageAnalysisData;

      const { studentName } = this.props.niaStudentInformation;

      this.setState({
        series: [
          {
            name: studentName,
            data: [
              impulsivenessScore,
              conceptUnderstandingScore,
              learningAttitudeScore,
              spacingScore,
              achievementScore,
            ],
          },
          {
            name: '최상위 1등급',
            data: [
              +topLevelAvgImpulsivenessScore.toFixed(0),
              +topLevelAvgConceptUnderstandingScore.toFixed(0),
              +topLevelAvgLearningAttitudeScore.toFixed(0),
              +topLevelAvgSpacingScore.toFixed(0),
              +topLevelAvgAchievementScore.toFixed(0),
            ],
          },
          {
            name: '평균',
            data: [
              +avgImpulsivenessScore.toFixed(0),
              +avgConceptUnderstandingScore.toFixed(0),
              +avgLearningAttitudeScore.toFixed(0),
              +avgSpacingScore.toFixed(0),
              +avgAchievementScore.toFixed(0),
            ],
          },
        ],
      });
    }
  }

  render() {
    return (
      <Container maxW={'7xl'} p="2">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={350}
        />
      </Container>
    );
  }
}

export default Barchart;
