import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { purgeAll } from '@app/store/actions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import logo from '../../assets/images/logo/dcsimanLogo.png';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function WithSubnavigation() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isOpen, onToggle } = useDisclosure();
  // const { showModal, setShowModal } = useDisclosure();
  // const { isOpen: showModal, onOpen: openModal, onClose: closeModal } = useDisclosure();
  // const [showModal, setShowModal] = useState(false);

  const handleLogout = () => {
    if (window.confirm('로그아웃 하시겠어요?')) {
      dispatch(purgeAll());
      history.replace('/auth');
    }
  };

  // const handleProfileUpdate = () => {
    
  // };

  // const handlePasswordChange = () => {
    
  // };

  return (
    <Box position="sticky" top="0" zIndex="sticky">
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
      >
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <Text
            textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
            fontFamily={'Noto Sans KR'}
            fontWeight={800}
            fontSize={'xl'}
            color={useColorModeValue('gray.800', 'white')}
            cursor="pointer"
            onClick={() => history.push('/')}
          >
            {/* <Image
              src={logo}
              alt="logo"
              width="50px"
              height="50px"
              display={{ base: 'none', md: 'inline-flex' }}
            /> */}
            닥터 사이먼
          </Text>

          <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>
        <Stack
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          direction={'row'}
          spacing={6}
        >
          <Button
            as={'a'}
            display={{ base: 'none', md: 'inline-flex' }}
            fontSize={'sm'}
            fontFamily={'Noto Sans KR'}
            fontWeight={600}
            color={'white'}
            bg={'gray.300'}
            onClick={() => handleLogout()}
            _hover={{
              bg: 'gray.400',
              color: 'white',
            }}
          >
            로그아웃
          </Button>
          {/* <IconButton 
            variant={'ghost'}
            icon={<AccountCircleIcon style={{width: 34, height: 34, color:'#EC407A' }} />}
            onClick={setShowModal}
          /> */}
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>

      {/* <Flex in={showModal}>
        <ProfileNav />
      </Flex> */}
    </Box>
  );
}

// const ProfileNav = () => {
//   const dispatch = useDispatch();
//   const history = useHistory();

//   const handleLogout = () => {
//     if (window.confirm('로그아웃 하시겠어요?')) {
//       dispatch(purgeAll());
//       history.replace('/auth');
//     }
//   };

//   return (
//     <Stack
//       bg={useColorModeValue('white', 'gray.800')}
//       p={4}
//       display={{ md: 'none' }}
//     >
//       {/* {NAV_ITEMS.map((navItem) => (
//         <MobileNavItem key={navItem.label} {...navItem} />
//       ))} */}
//       <Text
//         variant={'solid'}
//         // colorScheme={'teal'}
//         color={'gray.400'}
//         fontFamily={'Noto Sans KR'}
//         size={'sm'}
//         mr={4}
//         onClick={() => handleLogout()}
//       >
//         로그아웃
//       </Text>
//     </Stack>
//   );
// };

const DesktopNav = () => {
  const linkColor = useColorModeValue('gray.600', 'gray.200');
  const linkHoverColor = useColorModeValue('gray.800', 'white');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');

  return (
    <Stack direction={'row'} spacing={4} alignItems={'center'}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Link
                p={2}
                href={navItem.href ?? '#'}
                fontSize={'sm'}
                fontFamily={'Noto Sans KR'}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
  return (
    <Link
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}
    >
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'pink.400' }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}
        >
          <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    if (window.confirm('로그아웃 하시겠어요?')) {
      dispatch(purgeAll());
      history.replace('/auth');
    }
  };

  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{ md: 'none' }}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
      <Text
        variant={'solid'}
        // colorScheme={'teal'}
        color={'gray.400'}
        fontFamily={'Noto Sans KR'}
        size={'sm'}
        mr={4}
        onClick={() => handleLogout()}
      >
        로그아웃
      </Text>
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? '#'}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue('gray.600', 'gray.200')}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    // label: '대시보드',
    // children: [
    //   {
    //     label: '자녀의 데이터 조회',
    //     subLabel: '닥터 사이먼을 통해 분석된 자녀의 데이터를 분석합니다.',
    //     href: '#',
    //   },
    //   {
    //     label: '자녀의 데이터 조회2',
    //     subLabel: '닥터 사이먼을 통해 분석된 자녀의 데이터를 분석합니다.',
    //     href: '#',
    //   },
    // ],
  },
  // {
  //   label: '대시보드',
  //   children: [
  //     {
  //       label: '자녀의 데이터 조회3',
  //       subLabel: '닥터 사이먼을 통해 분석된 자녀의 데이터를 분석합니다.',
  //       href: '#',
  //     },
  //     {
  //       label: '자녀의 데이터 조회',
  //       subLabel: '닥터 사이먼을 통해 분석된 자녀의 데이터를 분석합니다.',
  //       href: '/dashboard',
  //     },
  //   ],
  // },
  {
    label: '자녀 공부 마음 보고서',
    href: '/dashboard',
  },
  {
    label: '분석보고서',
    href: '/analysisReport',
  },

  // {
  //   label: '계정 관리',
  //   href: '/mypage',
  // },
];
