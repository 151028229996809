// 문단 간격 정의
export const paragraphMargin = 10;
// 들여쓰기
export const paragraphIndent = 40;
// 줄간격
export const paragraphLineHeight = 1.8;
// 문단간격 벌리기
export const paragraphSpacing = 4;

//모바일 아닐 때 가로길이 50%로 사용
export const WIDTHS = window.innerWidth >= 480 ? '50%': '100%'; 
//모바일 아닐 때 가로길이 70%로 사용
export const WIDTHS2 = window.innerWidth >= 480 ? '70%': '100%'; 
//모바일 아닐 때 가로길이 30%로 사용
export const WIDTHS3 = window.innerWidth >= 480 ? '30%': '100%'; 