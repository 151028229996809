import axios from "axios";
// import { REACT_APP_BACKEND_ADDR, REACT_APP_ROOT_AUTH_TOKEN } from 'process.env'
// import { getStorageData, storeStorageData } from './storage';

const Backend = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ADDR,
});

const BackendAlt = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ADDR_ALT,
});

const NEISBackend = axios.create({
  baseURL: process.env.REACT_APP_NEIS_OPEN_DATA_ADDR,
});

const BackendAddrSlash = process.env.REACT_APP_BACKEND_ADDR;
const BackendAddr = BackendAddrSlash.substring(0, BackendAddrSlash.length - 1);

let header = {
  headers: {
    Authorization: '',
    "Access-Control-Max-Age": 600,
  },
};

export const resetToken = async () => {
  console.log("Resetting token");
  if ("Authorization" in header.headers) {
    delete header.headers.Authorization;
  }
  return header;
};

export const refreshToken = async () => {
  if (header.headers.Authorization === '') {
    const token = localStorage.getItem('token');
    if (token) {
      header.headers.Authorization = `Bearer ${token}`;
    }
  }
};

export const setToken = async (token) => {
  if (token === 'undefined' || token === 'null' || token == undefined || token == null) return
  localStorage.setItem('token', token);
  // console.log('local', localStorage.getItem('token'))
  header.headers.Authorization = `Bearer ${token}`;
  return header;
};

export const setRootToken = async () => {
  const root_user = {
    id: 1,
    token: process.env.REACT_APP_ROOT_AUTH_TOKEN,
  };
  console.log("Setting token to: ", root_user.token);
  header.headers.Authorization = `Bearer ${root_user.token}`;
  return header;
};

export const getBackend = async (endpoint, annonymous = false) => {
  await refreshToken();
  if (annonymous) {
    // return await Backend.get(endpoint, { headers: { Authorization: "" } });
    // return await Backend.get(endpoint);
    return await Backend.get(endpoint, {
      headers: { Authorization: '' },
    });
  }
  return await Backend.get(endpoint, {
    headers: { Authorization: header.headers.Authorization },
  });
};

export const getBackendAlt = async (endpoint, annonymous = false) => {
  if (annonymous) {
    return await BackendAlt.get(endpoint, { headers: { Authorization: "" } });
  }
  console.log("getBackendAlt: ", endpoint, header);
  return await BackendAlt.get(endpoint, header);
};

export const postBackend = async (
  endpoint,
  payload = {},
  annonymous = false
) => {
  await refreshToken();
  if (annonymous) {
    console.log("postBackend: ", endpoint, payload);
    // return await Backend.post(endpoint, payload);
    return await Backend.post(endpoint, payload, {
      headers: { Authorization: '' },
    });
  }
  console.log("postBackend: ", endpoint, payload);
  return await Backend.post(endpoint, payload, {
    headers: { Authorization: header.headers.Authorization },
  });
};

export const postBackendAlt = async (
  endpoint,
  payload = {},
  annonymous = false
) => {
  if (annonymous) {
    return await BackendAlt.post(endpoint, payload, {
      headers: { Authorization: "" },
    });
  }
  console.log("postBackendAlt: ", endpoint, payload, header);
  return await BackendAlt.post(endpoint, payload, header);
};

export const patchBackend = async (
  endpoint,
  payload = {},
  annonymous = false
) => {
  if (annonymous) {
    return await Backend.patch(endpoint, payload, {
      headers: { Authorization: '' },
    });
  }
  return await Backend.patch(endpoint, payload, {
    headers: { Authorization: header.headers.Authorization },
  });
};


export const deleteBackend = async (
  endpoint,
  payload = {},
  annonymous = false
) => {
  await refreshToken();
  if (annonymous) {
    return await Backend.delete(endpoint,
      { headers: { Authorization: '' } },
      payload
    );
  }
  return await Backend.delete(endpoint,
    { headers: { Authorization: header.headers.Authorization } },
    payload
  )
};


export const patchBackendAlt = async (
  endpoint,
  payload = {},
  annonymous = false
) => {
  if (annonymous) {
    return await BackendAlt.patch(endpoint, payload, {
      headers: { Authorization: "" },
    });
  }
  console.log("patchBackendAlt: ", endpoint, payload, header);
  return await BackendAlt.patch(endpoint, payload, header);
};

export const postBackendFormData = async (
  endpoint,
  payload = {},
) => {
  const formData = new FormData();
  Object.keys(payload).map(k => {
    formData.append(k, payload[k]);
  });
  return await Backend.post(endpoint, formData, {
    headers: {
      ...header.headers,
      'Content-Type': 'multipart/form-data',
    },
  });
};