import {
  PURGE_GROUP,
  PURGE_GROUP_TEMP,
  GET_GROUP,
  GET_GROUP_SUCCESS,
  GET_GROUP_FAIL,
  GET_GROUP_BY_INVITE_SUCCESS,
  GET_GROUP_BY_INVITE_FAIL,
  GET_GROUP_TEMP,
  GET_GROUP_TEMP_SUCCESS,
  GET_GROUP_TEMP_FAIL,
  FIND_GROUP,
  FIND_GROUP_SUCCESS,
  FIND_GROUP_FAIL,
  GET_MY_GROUPS,
  GET_MY_GROUPS_SUCCESS,
  GET_MY_GROUPS_FAIL,
  GET_GROUPS,
  GET_GROUPS_SUCCESS,
  GET_GROUPS_FAIL,
  GET_GROUP_STUDENTS,
  GET_GROUP_STUDENTS_SUCCESS,
  GET_GROUP_STUDENTS_FAIL,
  GET_GROUP_CLIENTS,
  GET_GROUP_CLIENTS_SUCCESS,
  GET_GROUP_CLIENTS_FAIL,
  CREATE_GROUP,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAIL,
  UPDATE_GROUP,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAIL,
  DELETE_GROUP,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAIL,
  INVITE_STUDENTS,
  INVITE_STUDENTS_SUCCESS,
  INVITE_STUDENTS_FAIL,
  KICK_STUDENTS,
  KICK_STUDENTS_SUCCESS,
  KICK_STUDENTS_FAIL,
  REQUEST_GROUP,
  REQUEST_GROUP_SUCCESS,
  REQUEST_GROUP_FAIL,
  ACCEPT_GROUP_REQUEST,
  ACCEPT_GROUP_REQUEST_SUCCESS,
  ACCEPT_GROUP_REQUEST_FAIL,
  DECLINE_GROUP_REQUEST,
  DECLINE_GROUP_REQUEST_SUCCESS,
  DECLINE_GROUP_REQUEST_FAIL,
  ACCEPT_GROUP_INVITE,
  ACCEPT_GROUP_INVITE_SUCCESS,
  ACCEPT_GROUP_INVITE_FAIL,
  DECLINE_GROUP_INVITE,
  DECLINE_GROUP_INVITE_SUCCESS,
  DECLINE_GROUP_INVITE_FAIL,
  ACTIVATE_GROUP,
  ACTIVATE_GROUP_SUCCESS,
  ACTIVATE_GROUP_FAIL,
  DEACTIVATE_GROUP,
  DEACTIVATE_GROUP_SUCCESS,
  DEACTIVATE_GROUP_FAIL,
  SIGN_UP_STUDENTS,
  SIGN_UP_STUDENTS_SUCCESS,
  SIGN_UP_STUDENTS_FAIL,
  SIGN_UP_STUDENT,
  SIGN_UP_STUDENT_SUCCESS,
  SIGN_UP_STUDENT_FAIL,
} from './actionTypes';
import * as api from '@api';
import { createPromiseAction } from '@adobe/redux-saga-promise';

export const purgeGroup = () => ({
  type: PURGE_GROUP,
});

export const purgeGroupTemp = () => ({
  type: PURGE_GROUP_TEMP,
});

///// GET_GROUP

export const getGroup = (groupId) => ({
  type: GET_GROUP,
  groupId: groupId,
});

export const getGroupSuccess = (groupData) => ({
  type: GET_GROUP_SUCCESS,
  group: groupData,
});

export const getGroupFail = (error) => ({
  type: GET_GROUP_FAIL,
  payload: error,
});

export const getGroupByInviteSuccess = (groupData) => ({
  type: GET_GROUP_BY_INVITE_SUCCESS,
  decipherGroup: groupData,
});

export const getGroupByInviteFail = (error) => ({
  type: GET_GROUP_BY_INVITE_FAIL,
  payload: error,
});


///// GET_GROUP_TEMP

export const getGroupTemp = (groupId) => ({
  type: GET_GROUP_TEMP,
  groupId: groupId,
});

export const getGroupTempSuccess = (groupData) => ({
  type: GET_GROUP_TEMP_SUCCESS,
  group: groupData,
});

export const getGroupTempFail = (error) => ({
  type: GET_GROUP_TEMP_FAIL,
  payload: error,
});

///// FIND_GROUP

export const findGroup = (groupName) => ({
  type: FIND_GROUP,
  groupName: groupName,
});

export const findGroupSuccess = (groupData) => {
  let byIdTemp = {};
  groupData.forEach((groupData) => {
    byIdTemp[groupData.id] = groupData;
  });

  const allIdsTemp = groupData.map((groupData) => groupData.id);
  return {
    type: FIND_GROUP_SUCCESS,
    byIdTemp: byIdTemp,
    allIdsTemp: allIdsTemp,
  };
};

export const findGroupFail = (error) => ({
  type: FIND_GROUP_FAIL,
  payload: error,
});

///// GET_MY_GROUPS

export const getMyGroups = () => ({
  type: GET_MY_GROUPS,
});

export const getMyGroupsSuccess = (groupsData) => {
  let byId = {};
  groupsData.forEach((groupData) => {
    byId[groupData.id] = groupData;
  });
  const allIds = groupsData.map((groupData) => groupData.id);
  return {
    type: GET_MY_GROUPS_SUCCESS,
    byId: byId,
    allIds: allIds,
  };
};

export const getMyGroupsFail = (error) => ({
  type: GET_MY_GROUPS_FAIL,
  payload: error,
});

export const getMyGroupsPromise = createPromiseAction('GET_MY_GROUPS_PROMISE');

///// GET_GROUPS

export const getGroups = (groupIds) => ({
  type: GET_GROUPS,
  groupId: groupIds,
});

export const getGroupsSuccess = (groupsData) => {
  let byId = {};
  groupsData.forEach((groupData) => {
    byId[groupData.id] = groupData;
  });

  const allIds = groupsData.map((groupData) => groupData.id);
  return {
    type: GET_GROUPS_SUCCESS,
    byId: byId,
    allIds: allIds,
  };
};


export const getGroupsByMembershipSuccess = (groupMembershipsData) => {
  let byId = {};
  groupMembershipsData.forEach((groupMembershipData) => {
    byId[groupMembershipData.group.groupId] = groupMembershipData.group});
    
  const allIds = groupMembershipsData
    .map((groupMembershipData) => groupMembershipData.group.groupId);

  return {
    type: GET_GROUPS_SUCCESS,
    byId: byId,
    allIds: allIds,
  };
};

export const getGroupsFail = (error) => ({
  type: GET_GROUPS_FAIL,
  payload: error,
});

export const getGroupsPromise = createPromiseAction('GET_GROUPS_PROMISE');

///// GET_CLASSROOM_STUDENTS

export const getGroupStudentsPromise = createPromiseAction(
  'GET_GROUP_STUDENTS_PROMISE'
);

export const getGroupStudents = (classroomId) => ({
  type: GET_GROUP_STUDENTS,
  classroomId: classroomId,
});

export const getGroupStudentsSuccess = (classStudentsData) => {
  return {
    type: GET_GROUP_STUDENTS_SUCCESS,
    students: { ...classStudentsData},
  };
};

export const getGroupStudentsFail = (error) => ({
  type: GET_GROUP_STUDENTS_FAIL,
  payload: error,
});

///// GET_GROUP_CLIENTS

export const getGroupClients = (groupId) => ({
  type: GET_GROUP_CLIENTS,
  groupId: groupId,
});

export const getGroupClientsSuccess = () => ({
  type: GET_GROUP_CLIENTS_SUCCESS,
});

export const getGroupClientsFail = (error) => ({
  type: GET_GROUP_CLIENTS_FAIL,
  payload: error,
});

///// CREATE_GROUP

export const createGroupPromise = createPromiseAction('CREATE_GROUP_PROMISE');

export const createGroup = (group) => ({
  type: CREATE_GROUP,
  groupData: group,
});

export const createGroupSuccess = (groupData) => ({
  type: CREATE_GROUP_SUCCESS,
  group: groupData,
});

export const createGroupFail = (error) => ({
  type: CREATE_GROUP_FAIL,
  payload: error,
});

///// UPDATE_GROUP

export const updateGroup = (group) => ({
  type: UPDATE_GROUP,
  groupData: group,
});

export const updateGroupSuccess = (groupData) => ({
  type: UPDATE_GROUP_SUCCESS,
  group: groupData,
});

export const updateGroupFail = (error) => ({
  type: UPDATE_GROUP_FAIL,
  payload: error,
});

///// DELETE_GROUP
export const deleteGroupPromise = createPromiseAction('DELETE_GROUP_PROMISE');

export const deleteGroup = (group) => ({
  type: DELETE_GROUP,
  group: group,
});

// note shallow delete
export const deleteGroupSuccess = (group) => ({
  type: DELETE_GROUP_SUCCESS,
  group: { ...group, deleted: true },
});

export const deleteGroupFail = (error) => ({
  type: DELETE_GROUP_FAIL,
  payload: error,
});

///// INVITE_STUDENTS

export const inviteStudents = (group, students) => ({
  type: INVITE_STUDENTS,
  group: group,
  students: students,
});

export const inviteStudentsSuccess = () => ({
  type: INVITE_STUDENTS_SUCCESS,
});

export const inviteStudentsFail = (error) => ({
  type: INVITE_STUDENTS_FAIL,
  payload: error,
});

///// KICK_STUDENTS
export const kickStudentsPromise = createPromiseAction('KICK_STUDENTS_PROMISE');

export const kickStudents = (groupId, studentIds) => ({
  type: KICK_STUDENTS,
  groupId: groupId,
  studentIds: studentIds,
});

export const kickStudentsSuccess = (groupData) => ({
  type: KICK_STUDENTS_SUCCESS,
  group: groupData,
});

export const kickStudentsFail = (error) => ({
  type: KICK_STUDENTS_FAIL,
  payload: error,
});

///// REQUEST_GROUP
export const requestGroup = (groupId) => {
  return {
    type: REQUEST_GROUP,
    groupId: groupId,
  };
};

export const requestGroupSuccess = () => {
  return {
    type: REQUEST_GROUP_SUCCESS,
  };
};

export const requestGroupFail = (error) => {
  return {
    type: REQUEST_GROUP_FAIL,
    payload: error,
  };
};

//ACCEPT GROUP REQUEST
export const acceptGroupRequest = (requestId) => {
  return {
    type: ACCEPT_GROUP_REQUEST,
    requestId: requestId,
  };
};

export const acceptGroupRequestSuccess = () => {
  // const request = api.unpackRequest(requestData);
  return {
    type: ACCEPT_GROUP_REQUEST_SUCCESS,
    // request: request,
  };
};

export const acceptGroupRequestFail = (error) => {
  return {
    type: ACCEPT_GROUP_REQUEST_FAIL,
    payload: error,
  };
};

//DECLINE GROUP REQUEST
export const declineGroupRequest = (requestId) => {
  return {
    type: DECLINE_GROUP_REQUEST,
    requestId: requestId,
  };
};

export const declineGroupRequestSuccess = () => {
  // const request = api.unpackRequest(requestData);
  return {
    type: DECLINE_GROUP_REQUEST_SUCCESS,
    // request: request,
  };
};

export const declineGroupRequestFail = (error) => {
  return {
    type: DECLINE_GROUP_REQUEST_FAIL,
    payload: error,
  };
};

//ACCEPT GROUP REQUEST
export const acceptGroupInvite = (requestId) => {
  return {
    type: ACCEPT_GROUP_INVITE,
    requestId: requestId,
  };
};

export const acceptGroupInviteSuccess = () => {
  // const request = api.unpackRequest(requestData);
  return {
    type: ACCEPT_GROUP_INVITE_SUCCESS,
    // request: request,
  };
};

export const acceptGroupInviteFail = (error) => {
  return {
    type: ACCEPT_GROUP_INVITE_FAIL,
    payload: error,
  };
};

//DECLINE GROUP REQUEST
export const declineGroupInvite = (requestId) => {
  return {
    type: DECLINE_GROUP_INVITE,
    requestId: requestId,
  };
};

export const declineGroupInviteSuccess = () => {
  // const request = api.unpackRequest(requestData);
  return {
    type: DECLINE_GROUP_INVITE_SUCCESS,
    // request: request,
  };
};

export const declineGroupInviteFail = (error) => {
  return {
    type: DECLINE_GROUP_INVITE_FAIL,
    payload: error,
  };
};

/////ACTIVATE, DEACTIVATE

export const activateGroup = (groupId) => {
  return {
    type: ACTIVATE_GROUP,
    groupId: groupId,
  };
};

export const activateGroupSuccess = (serverResponse) => {
  const responseString = serverResponse;
  console.log('activateGroup, serverResponse:', responseString);
  return {
    type: ACTIVATE_GROUP_SUCCESS,
  };
};

export const activateGroupFail = (error) => {
  return {
    type: ACTIVATE_GROUP_FAIL,
    payload: error,
  };
};

export const deactivateGroup = (groupId) => {
  return {
    type: DEACTIVATE_GROUP,
    groupId: groupId,
  };
};

export const deactivateGroupSuccess = () => {
  return {
    type: DEACTIVATE_GROUP_SUCCESS,
  };
};

export const deactivateGroupFail = (error) => {
  return {
    type: DEACTIVATE_GROUP_FAIL,
    payload: error,
  };
};

// SIGN UP STUDENTS
export const signUpStudentsPromise = createPromiseAction(
  'SIGN_UP_STUDENTS_PROMISE'
);

export const signUpStudents = (group, students) => ({
  type: SIGN_UP_STUDENTS,
  group: group,
  students: students,
});

export const signUpStudentsSuccess = (studentsData) => ({
  type: SIGN_UP_STUDENTS_SUCCESS,
  group: studentsData,
});

export const signUpStudentsFail = (error) => ({
  type: SIGN_UP_STUDENTS_FAIL,
  payload: error,
});

// SIGN UP STUDENT

export const signUpStudent = (groupId, student) => ({
  type: SIGN_UP_STUDENT,
  groupId: groupId,
  student: student,
});

export const signUpStudentSuccess = () => ({
  type: SIGN_UP_STUDENT_SUCCESS,
});

export const signUpStudentFail = (error) => ({
  type: SIGN_UP_STUDENT_FAIL,
  payload: error,
});

export const requestGroupPromise = createPromiseAction('REQUEST_GROUP_PROMISE');
export const inviteStudentsPromise = createPromiseAction(
  'INVITE_STUDENTS_PROMISE'
);
export const acceptGroupInvitePromise = createPromiseAction(
  'ACCEPT_GROUP_INVITE_PROMISE'
);
export const acceptGroupRequestPromise = createPromiseAction(
  'ACCEPT_GROUP_REQUEST_PROMISE'
);
export const declineGroupInvitePromise = createPromiseAction(
  'DECLINE_GROUP_INVITE_PROMISE'
);
export const declineGroupRequestPromise = createPromiseAction(
  'DECLINE_GROUP_REQUEST_PROMISE'
);

export const getGroupByInviteCodePromise = createPromiseAction(
  'GET_GROUP_BY_INVITE_CODE_PROMISE'
);