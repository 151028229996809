export const PURGE_GROUP = 'PURGE_GROUP';
export const PURGE_GROUP_TEMP = 'PURGE_GROUP_TEMP';

export const GET_GROUP = 'GET_GROUP'
export const GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS'
export const GET_GROUP_FAIL = 'GET_GROUP_FAIL'

export const GET_GROUP_BY_INVITE_SUCCESS = 'GET_GROUP_BY_INVITE_SUCCESS'
export const GET_GROUP_BY_INVITE_FAIL = 'GET_GROUP_BY_INVITE_FAIL'

export const GET_GROUP_STUDENTS = 'GET_GROUP_STUDENTS'
export const GET_GROUP_STUDENTS_SUCCESS = 'GET_GROUP_STUDENTS_SUCCESS'
export const GET_GROUP_STUDENTS_FAIL = 'GET_GROUP_STUDENTS_FAIL'

export const GET_GROUP_TEMP = 'GET_GROUP_TEMP'
export const GET_GROUP_TEMP_SUCCESS = 'GET_GROUP_TEMP_SUCCESS'
export const GET_GROUP_TEMP_FAIL = 'GET_GROUP_TEMP_FAIL'

export const FIND_GROUP = 'FIND_GROUP'
export const FIND_GROUP_SUCCESS = 'FIND_GROUP_SUCCESS'
export const FIND_GROUP_FAIL = 'FIND_GROUP_FAIL'

export const GET_MY_GROUPS = 'GET_MY_GROUPS'
export const GET_MY_GROUPS_SUCCESS = 'GET_MY_GROUPS_SUCCESS'
export const GET_MY_GROUPS_FAIL = 'GET_MY_GROUPS_FAIL'

export const GET_GROUPS = 'GET_GROUPS'
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS'
export const GET_GROUPS_FAIL = 'GET_GROUPS_FAIL'

export const GET_GROUP_CLIENTS = 'GET_GROUP_CLIENTS'
export const GET_GROUP_CLIENTS_SUCCESS = 'GET_GROUP_CLIENTS_SUCCESS'
export const GET_GROUP_CLIENTS_FAIL = 'GET_GROUP_CLIENTS_FAIL'

export const CREATE_GROUP = 'CREATE_GROUP'
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS'
export const CREATE_GROUP_FAIL = 'CREATE_GROUP_FAIL'

export const UPDATE_GROUP = 'UPDATE_GROUP'
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS'
export const UPDATE_GROUP_FAIL = 'UPDATE_GROUP_FAIL'

export const DELETE_GROUP = 'DELETE_GROUP';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAIL = 'DELETE_GROUP_FAIL';

export const INVITE_STUDENTS = 'INVITE_STUDENTS';
export const INVITE_STUDENTS_SUCCESS = 'INVITE_STUDENTS_SUCCESS';
export const INVITE_STUDENTS_FAIL = 'INVITE_STUDENTS_FAIL';

export const KICK_STUDENTS = 'KICK_STUDENTS';
export const KICK_STUDENTS_SUCCESS = 'KICK_STUDENTS_SUCCESS';
export const KICK_STUDENTS_FAIL = 'KICK_STUDENTS_FAIL';

export const REQUEST_GROUP = 'REQUEST_GROUP';
export const REQUEST_GROUP_SUCCESS = 'REQUEST_GROUP_SUCCESS';
export const REQUEST_GROUP_FAIL = 'REQUEST_GROUP_FAIL';

export const ACCEPT_GROUP_REQUEST = 'ACCEPT_GROUP_REQUEST';
export const ACCEPT_GROUP_REQUEST_SUCCESS = 'ACCEPT_GROUP_REQUEST_SUCCESS';
export const ACCEPT_GROUP_REQUEST_FAIL = 'ACCEPT_GROUP_REQUEST_FAIL';

export const DECLINE_GROUP_REQUEST = 'DECLINE_GROUP_REQUEST';
export const DECLINE_GROUP_REQUEST_SUCCESS = 'DECLINE_GROUP_REQUEST_SUCCESS';
export const DECLINE_GROUP_REQUEST_FAIL = 'DECLINE_GROUP_REQUEST_FAIL';

export const ACCEPT_GROUP_INVITE = 'ACCEPT_GROUP_INVITE';
export const ACCEPT_GROUP_INVITE_SUCCESS = 'ACCEPT_GROUP_INVITE_SUCCESS';
export const ACCEPT_GROUP_INVITE_FAIL = 'ACCEPT_GROUP_INVITE_FAIL';

export const DECLINE_GROUP_INVITE = 'DECLINE_GROUP_INVITE';
export const DECLINE_GROUP_INVITE_SUCCESS = 'DECLINE_GROUP_INVITE_SUCCESS';
export const DECLINE_GROUP_INVITE_FAIL = 'DECLINE_GROUP_INVITE_FAIL';

export const ACTIVATE_GROUP = 'ACTIVATE_GROUP';
export const ACTIVATE_GROUP_SUCCESS = 'ACTIVATE_GROUP_SUCCESS';
export const ACTIVATE_GROUP_FAIL = 'ACTIVATE_GROUP_FAIL';

export const DEACTIVATE_GROUP = 'DEACTIVATE_GROUP';
export const DEACTIVATE_GROUP_SUCCESS = 'DEACTIVATE_GROUP_SUCCESS';
export const DEACTIVATE_GROUP_FAIL = 'DEACTIVATE_GROUP_FAIL';

export const SIGN_UP_STUDENTS = 'SIGN_UP_STUDENTS';
export const SIGN_UP_STUDENTS_SUCCESS = 'SIGN_UP_STUDENTS_SUCCESS';
export const SIGN_UP_STUDENTS_FAIL = 'SIGN_UP_STUDENTS_FAIL';

export const SIGN_UP_STUDENT = 'SIGN_UP_STUDENT';
export const SIGN_UP_STUDENT_SUCCESS = 'SIGN_UP_STUDENT_SUCCESS';
export const SIGN_UP_STUDENT_FAIL = 'SIGN_UP_STUDENT_FAIL';