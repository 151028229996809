import { call, put, takeLatest, takeLeading } from 'redux-saga/effects';
import { PURGE_ACCOUNT } from './actionTypes';
import {
  registerTeacherSuccess,
  registerTeacherFail,
  registerTeacherPromise,
} from '@store/actions';
import * as api from '@api';
import { implementPromiseAction } from '@adobe/redux-saga-promise';
import {
  getAccountInfoByAccountIdFail,
  getAccountInfoByAccountIdPromise,
  getAccountInfoByAccountIdSuccess,
} from './actions';

function* registerTeacherPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.registerTeacher, action.payload);
      yield put(registerTeacherSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(registerTeacherFail(error));
    }
  });
}

function* getAccountInfoByAccountIdPromiseHandler(accountData) {
  yield call(implementPromiseAction, accountData, function*() {
    try {
      const response = yield call(
        api.getAccountInfoByAccountId,
        accountData.payload
      );
      // console.log('response', response.data);
      yield put(getAccountInfoByAccountIdSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getAccountInfoByAccountIdFail(error));
    }
  });
}

function* accountSaga() {
  yield takeLeading(registerTeacherPromise, registerTeacherPromiseHandler);
  yield takeLatest(
    getAccountInfoByAccountIdPromise,
    getAccountInfoByAccountIdPromiseHandler
  );
}

export default accountSaga;
