import React, { useEffect, useState, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import styles from "./InviteTeacherModal.module.scss";
import useInput from '../../hooks/useInput';
import styled from 'styled-components';
import {
  createManagerTeacherAdminPromise,
  getAllManagerTeachersAdminPromise,
  updateTeacherInfoAdminPromise
} from '@store/actions';
import ReactGA from 'react-ga';

const ErrorMessage = styled.div`
  color: red;
`;

function InviteManagerTeacherModal({ show, setShow, school_id,  }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const totalList = useSelector((state) => state.clients.allTeacher); // teacher + manager
  const [gender, setGender] = useState(true);
  const [name, setName] = useState('');
  const [account, setAccount] = useState('');
  const [birthToString, setBirthToString] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [email, onChangeEmail] = useInput('');
  const [emailCheck, setEmailCheck] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [phone, onChangePhone] = useInput('');
  const [password, onChangePassword] = useInput('');
  const [passwordCheck, setPasswordCheck] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [agreeChkbox, setAgreeChkbox] = useState(true); //제 3자 정보 제공 활용에 동의 체크 박스
  const [privacyChkbox, setprivacyChkbox] = useState(true); //개인정보 동의 약관 체크 박스
  const [anotherChkbox, setanotherChkbox] = useState(true); //제 3자 정보 제공 활용에 동의 체크 박스
  const accountHandler = (e) => setAccount(e.target.value);
  const nameHandler = (e) => setName(e.target.value);
  const randomPW = Math.random().toString(36).slice(2);
  const [temporaryPW, setTemporaryPW] = useState(false); //Issuance of temporary password

  const onChangePasswordCheck = useCallback(
    (e) => {
      setPasswordCheck(e.target.value);
      setPasswordError(e.target.value !== password);
    },
    [password]
  );

  const onChangeEmailCheck = useCallback((e) => {
    const emailRegex = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
    const emailCurrent = e.target.value

    if (!emailRegex.test(emailCurrent)) {
      setEmailMessage('이메일 형식이 틀렸어요. 다시 입력해주세요')
    } else {
      setEmailMessage('올바른 이메일 형식이에요')
      setEmailCheck(e.target.value)
    }
  }, [])

  const nameRegex = /^[가-힣]+$/;

  const onSubmit = (e) => {
    setDisabled(true);
    // e.preventDefault();

    if (nameRegex.test(name)==false)  {
      alert('이름은 한글로만 채워주세요');
    } else
    if (name === '' || emailCheck === '') {
      alert('관리자 이름, 이메일을 모두 채워주세요');
    } else {
      if(password!='') { randomPW= password;}
      var payload={
        schoolId: school_id,
        email: emailCheck,
        userName: name, 
        password: randomPW,
      };
      dispatch(createManagerTeacherAdminPromise(payload))
        .then((clientData) => {
          if (clientData === undefined) {
            throw new Error('already signed up!');
          }
          setShow(false);
          dispatch(getAllManagerTeachersAdminPromise(school_id))
            .then((managerteachersData) => {
              if (managerteachersData === undefined) {
                throw new Error('already signed up!');
              }
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
          const addUser = totalList?.filter((userInfo)=>userInfo.account.email === emailCheck);
          dispatch(
            updateTeacherInfoAdminPromise({
              accountId: addUser[0]?.account.accountId,
              role: "ADMIN",
            })
          ).then((userInfo) => {
              if (userInfo === undefined) {
                throw new Error("login failed");
              }
              console.log("updateUserInfoPromise user info : ", userInfo);
              dispatch(getAllManagerTeachersAdminPromise(userInfo.schoolId))
                .then((teachersData) => {
                  if (teachersData === undefined) {
                    throw new Error('already signed up!');
                  }
                })
                .catch((error) => {
                  console.error(error);
                  alert('관리자 등록에 실패했습니다')
                });
              setShow(false);
            })
            .catch((error) => {
              console.log("userUpdate error", error);
              alert('이미 사용중인 이메일입니다');
            });
        });
        setShow(false);
      setEmailMessage('');
    }
    setDisabled(false);
    setTemporaryPW(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      centered
      className={styles["modal"]}
    >
      <Modal.Header closeButton>
        <Modal.Title>관리자 선생님 정보를 입력해주세요</Modal.Title>
      </Modal.Header>
      <div>
        {/* <form onSubmit={onSubmit} className={styles['form']}> */}
          <div className={styles['outerContainer']}>
          <div className={styles['acceptedAnnounce']}>* 개인정보 제공 동의 및 제 3자 제공 동의 절차를{"\n"}모두 마무리하였는지 확인 후 선생님 등록부탁드립니다.</div>
            <div className={styles['infoContainer']}>
              <div className={styles['infoFont']}> 이름</div>
              <input
                type="text"
                name="name"
                onChange={nameHandler}
                className={styles['inputContainer']}
              />
            </div>
            <div className={styles['infoContainer']}>
              <div className={styles['infoFont']}>이메일</div>
              <input
                type="text"
                name="email"
                onChange={onChangeEmailCheck}
                className={styles['inputContainer']}
              />
            </div>
            <div className={styles['emailRegex']}>{emailMessage}</div>
            {passwordError && (
              <ErrorMessage>비밀번호가 일치하지 않습니다. </ErrorMessage>
            )}

            {temporaryPW ? (
              <>
                <div className={styles['passwordAnnounce']}>
                  초기 비밀번호는{" "}<div style={{color: 'red', fontSize:20}}>{randomPW}</div>입니다.
                </div>
                <div className={styles['passwordAnnounce2']}>
                  초기 비밀번호는 지금 페이지에서만 확인가능합니다.{"\n"} 
                  복사 후 해당 계정 선생님께 전달부탁드립니다.{"\n"}
                  초기 비밀번호를 잊으신 경우에는 비밀번호 초기화를 해주세요.
                </div>
              </>
            ) : (
              <button
                type="randomPassword"
                name="randomPassword"
                className={styles['get-temporaryPW']}
                onClick={() => setTemporaryPW(true)}
              >
                초기 비밀번호 발급받기
              </button>
            )}
          </div>

          {temporaryPW ? (
            <button
              type="submit"
              name="submit"
              className={styles['button-input']}
              disabled={disabled}
              onClick={() => {
                onSubmit();
                ReactGA.event({
                  action: "Click Create Admin Teacher Account",
                  category: "Button",
                  label: "Manager",
                });
              }}
            >
              등록하기
            </button>
          ) : null}
        {/* </form> */}
      </div>
    </Modal>
  );


}

export default InviteManagerTeacherModal;
