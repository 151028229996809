import React from "react";
import { Route } from "react-router-dom";
import MasterManager from "managers";

const AppRoute = ({ component: Component, isAuthProtected, ...rest }) => {
  return(
    <div>
      <Route {...rest} render={(props) => <Component {...props} />} />
      <MasterManager />
    </div>
  )  
};
  
export default AppRoute;
