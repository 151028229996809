import React, { useEffect, useState } from 'react';
import WithSubnavigation from '@app/components/Layout/Navbar';
import LineChart from '@app/components/Chart/LineChart';
import {
  Box,
  Center,
  Container,
  Image,
  Tab,
  TabList,
  Tabs,
  Text,
  Button,
  Link,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import Comments from '@app/components/Comments';
import { useAnalysisByClientIdQuery } from '@app/hooks/queries/analysis';
import Barchart from '@app/components/Chart/BarChart';
import {
  useStudentInfoByClientQuery,
  useStudentInfoByClientQueries,
} from '@app/hooks/queries/student';
import { splitTextIntoObjects } from '@app/hooks/formatText/splitTextIntoObject';
// import EmptyStateComponent from '@app/components/EmptyState';
import { useAverageAnalysisQuery } from '@app/hooks/queries/averageAnalysis';
import emptyBox from '../../assets/images/empty/emptyBox.png';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  GetParentStudentJointPromise,
  getAccountInfoByAccountIdPromise,
  getSchoolPromise,
} from '@app/store/actions';
const Dashboard = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const childStudent = useSelector((state) => state.user.parentStudents);

  useEffect(() => {
    const schoolId = user.schoolId;
    if (schoolId == null) {
      //link to focuspang school
      dispatch(getSchoolPromise(1424));
    }
    dispatch(getSchoolPromise(schoolId));

    if (user) {
      dispatch(GetParentStudentJointPromise(user.clientId)).then((res) =>
        console.log('res', res)
      );
    }
  }, []);

  console.log('childStudent', childStudent);

  useEffect(() => {
    dispatch(getAccountInfoByAccountIdPromise(user.accountId));
    if (!user) {
      return;
    }
    if (childStudent[0]) {
      setActiveClientId(childStudent[0].existingStudentId);
    }
  }, [user]);

  const [activeClientId, setActiveClientId] = useState('');
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const userAnalysisDataQuery = useAnalysisByClientIdQuery(activeClientId);

  const niaStudentInformationQuery = useStudentInfoByClientQuery(
    activeClientId
  );

  const niaStudentInformationQuerys = useStudentInfoByClientQueries(
    childStudent
  );

  const averageAnalysisDataQuery = useAverageAnalysisQuery();

  if (
    userAnalysisDataQuery.isError ||
    niaStudentInformationQuery.isError ||
    niaStudentInformationQuerys.isError
  ) {
    return (
      <>
        <WithSubnavigation />
        <Center marginTop="20">
          <Box
            alignItems={'center'}
            display={'flex'}
            flexDirection={'column'}
            padding={'4'}
          >
            <Image
              src={emptyBox}
              alt="emptyBox"
              width="200px"
              height="200px"
              alignItems={'center'}
            />
            <Text>자녀의 공부 마음 보고서를 찾지 못했습니다.</Text>
            <Text>일시적인 문제거나, 시스템 상 오류일 수 있습니다.</Text>
            <Text>해당 페이지가 지속적으로 확인될 시</Text>
            <Text> 카카오 채널로 문의 부탁드립니다.</Text>
            <Box
              mt={'6'}
              bg={'gray.300'}
              p={4}
              width={'fit-content'}
              borderRadius={8}
            >
              <Link
                fontSize="lg"
                fontFamily={'Noto Serif KR'}
                href="http://pf.kakao.com/_xoEjHxj"
                isExternal
                my={'4'}
              >
                카카오톡 채널
                <ExternalLinkIcon mx="2px" />
              </Link>
            </Box>
          </Box>
        </Center>
      </>
    );
  }

  if (
    userAnalysisDataQuery.isLoading ||
    niaStudentInformationQuery.isLoading ||
    niaStudentInformationQuerys.isLoading
  ) {
    return (
      <>
        <WithSubnavigation />
        <Center
          marginTop="20"
          padding={'4'}
          alignItems={'center'}
          flexDirection={'column'}
        >
          <Text>닥터 사이먼이</Text>
          <Text> 자녀의 공부 마음 보고서를 가져오고 있어요</Text>
        </Center>
      </>
    );
  }

  const splitText = splitTextIntoObjects(
    userAnalysisDataQuery.data.humanCheckedComments
  );

  return (
    <>
      <WithSubnavigation />
      <Container maxW={'7xl'} p={0}>
        {childStudent.length > 1 ? (
          <Tabs
            mt={4}
            align="center"
            variant="soft-rounded"
            colorScheme="teal"
            onChange={(index) => {
              setActiveClientId(childStudent[index].existingStudentId);
              setSelectedTabIndex(index);
            }}
            index={selectedTabIndex}
          >
            <TabList>
              {niaStudentInformationQuerys.data.map((student, index) => (
                <Tab>{student.studentName}의 분석 결과</Tab>
              ))}
            </TabList>
            {}
          </Tabs>
        ) : (
          <Box></Box>
        )}
        <Barchart
          data={userAnalysisDataQuery.data}
          niaStudentInformation={niaStudentInformationQuery.data}
          averageAnalysisData={averageAnalysisDataQuery.data}
        />
        <Comments
          studentData={niaStudentInformationQuery.data}
          commentsData={userAnalysisDataQuery.data}
          splitText={splitText}
        />
      </Container>
    </>
  );
};

export default Dashboard;
