import React, { useEffect, useState, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import styles from "./InfoUpdateModal.module.scss";
import useInput from '../../hooks/useInput';
import styled from 'styled-components';
import {
  updateTeacherInfoAdminPromise,
  getAllTeachersAdminPromise,
  getAllManagerTeachersAdminPromise
} from '@store/actions';
const ErrorMessage = styled.div`
  color: red;
`;

function InfoUpdateModal({ show, setShow, propUsername, propEmail, propUser_id }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [name, setName] = useState('');
  const [account, setAccount] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [email, onChangeEmail] = useInput('');
  const [emailCheck, setEmailCheck] = useState('');
  const [emailMessage, setEmailMessage] = useState('')
  const [password, onChangePassword] = useInput('');
  const [passwordCheck, setPasswordCheck] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const accountHandler = (e) => setAccount(e.target.value);
  const nameHandler = (e) => setName(e.target.value);
  const onChangePasswordCheck = useCallback(
    (e) => {
      setPasswordCheck(e.target.value);
      setPasswordError(e.target.value !== password);
    },
    [password]
  );
  const list = useSelector((state) => state.clients.allTeacher); // teacher + manager
  const onChangeEmailCheck = useCallback((e) => {
 
    // setEmailCheck(e.targer.value);
    const emailRegex = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
    const emailCurrent = e.target.value
    const emailOverlapCheck = list.find((listData) => {
      return listData.account.email === emailCurrent
    })
    if (!emailRegex.test(emailCurrent)) {
      setEmailMessage('이메일 형식이 틀렸어요. 다시 입력해주세요')
      setDisabled(true);
      // setIsEmail(false)
    } else if(emailOverlapCheck !== undefined) {
      setEmailMessage('사용 중인 이메일입니다. 다시 입력해 주세요')
      setDisabled(true);
    } else {
      setEmailMessage('올바른 이메일 형식이에요')
      setDisabled(false);
      // setIsEmail(true)
      setEmailCheck(e.target.value)
    } 


  }, [])

  const onPassword = () => {
    dispatch(
      updateTeacherInfoAdminPromise({
        password: '0000',
        accountId: propUser_id,
      })
    ).then((userInfo) => {
        if (userInfo === undefined) {
          throw new Error("login failed");
        }
        console.log("updateUserInfoPromise user info : ", userInfo);
        dispatch(getAllTeachersAdminPromise(userInfo.schoolId))
          .then((teachersData) => {
            if (teachersData === undefined) {
              throw new Error('already signed up!');
            }
          })
          .catch((error) => {
            console.error(error);
          });
        setShow(false);
      })
      .catch((error) => {
        console.log("userUpdate error", error);
      });
    setDisabled(false);
  }

  const onSubmit = (e) => {
    // setDisabled(true);
    e.preventDefault();
    var nameCheck=name;
    if(nameCheck==''){
      nameCheck=propUsername;
    };
    var emailCheck2= emailCheck;
    if(emailCheck2=='') { 
      emailCheck2= propEmail;
    };
    const emailOverlapCheck = list.find((listData) => {
      return listData.account.email === emailCheck2
    })
    console.log('emailover',Boolean(emailOverlapCheck))
    if(emailOverlapCheck === undefined) {
      dispatch(
        updateTeacherInfoAdminPromise({
          userName: nameCheck,
          email: emailCheck2,
          accountId: propUser_id,
        })
      ).then((userInfo) => {
          console.log("updateUserInfoPromise user info 1: ", userInfo);
          if(userInfo.role === 'TEACHER'){
            dispatch(getAllTeachersAdminPromise(userInfo.schoolId));
          } else {
            dispatch(getAllManagerTeachersAdminPromise(userInfo.schoolId))
            .then((teachersData) => {
              // console.log(teachersData);
              // list + find = undefined? ok 
              if (teachersData === true) {
                // throw new Error('already signed up!');
              }
            })
            .catch((error) => {
              console.error(error);
            });
          };
          setShow(false);
        })
        .catch((error) => {
          console.log("userUpdate error", error);
        });
        alert('수정이 완료되었습니다.')
    } else if(emailOverlapCheck !== undefined || nameCheck === true){
      console.log('name')
      dispatch(
        updateTeacherInfoAdminPromise({
          userName: nameCheck,
          // email: emailCheck2,
          accountId: propUser_id,
        })
      ).then((userInfo) => {
          console.log("updateUserInfoPromise user info 1: ", userInfo);
          if(userInfo.role === 'TEACHER'){
            dispatch(getAllTeachersAdminPromise(userInfo.schoolId));
          } else {
            dispatch(getAllManagerTeachersAdminPromise(userInfo.schoolId))
            .then((teachersData) => {
              // console.log(teachersData);
              // list + find = undefined? ok 
             
              if (teachersData === true) {
                // throw new Error('already signed up!');
              }
            })
            .catch((error) => {
              console.error(error);
            });
          };
          setShow(false);
        })
        .catch((error) => {
          console.log("userUpdate error", error);
        });
        alert('수정이 완료되었습니다.')
    } 
    window.location.reload();
    // setDisabled(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      centered
      className={styles["modal"]}
    >
      <Modal.Header closeButton>
        <Modal.Title>수정할 정보를 입력해주세요</Modal.Title>
      </Modal.Header>
      <div>
        <form onSubmit={onSubmit} className={styles['form']}>
          <div className={styles['outerContainer']}>
             <div className={styles['info-title']}>[ 계정정보 ]</div>
             <div className={styles['pw-reset-text']}>수정하시고 싶은 정보를 입력하신 뒤 수정버튼을 눌러주세요</div>
            <div className={styles['infoContainer']}>
              <div className={styles['infoFont']}> 이름</div>
              <input
                type={'text'}
                value={name}
                onChange={nameHandler}
                placeholder={propUsername}
                className={styles['inputContainer']}
              />
            </div>
            <div className={styles['infoContainer']}>
              <div className={styles['infoFont']}>이메일</div>
              <input
                type="text"
                name="email"
                placeholder={propEmail}
                onChange={onChangeEmailCheck}
                className={styles['inputContainer']}
              />
            </div>
            <div className={styles['emailRegex']}>{emailMessage}</div>
          </div>
          <button
            type="submit"
            name="submit"
            className={styles['button-input']}
            disabled={disabled}
          >
            수정하기
          </button>
        </form>

        <div className={styles['info-title']}>[ 비밀번호 ]</div>
        <div className={styles['pw-reset-text']}>0000으로 비밀번호 초기화를 원하시면 초기화버튼을 눌러주세요</div>
         <button
          className={styles['pw-reset-button']}
          onClick={onPassword}
          >비밀번호 초기화
          </button>
      </div>
    </Modal>
  );


}

export default InfoUpdateModal;
