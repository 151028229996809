import React, { useCallback, useState } from 'react';
import WithSubnavigation from '@app/components/Layout/Navbar';
import { Container, Box, Heading, Text, useColorModeValue, Input, Button } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { paragraphSpacing } from '@app/assets/theme/typoStyle';
import { signInByEmailPromise, updateAccountInfoPromise } from '@app/store/actions';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LockIcon from '@mui/icons-material/Lock';

const MyPage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [currentPassword, setCurrentPassword] = useState('');
  const [ChangeNext, setChangeNext] = useState(false);
  const [PWdisabled, setPWDisabled] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPw, setConfirmedPw] = useState('');

  const onCurrentPWCheck = () => {
    dispatch(
        signInByEmailPromise({
          email: user.email,
          password: currentPassword,
          role: 'TEACHER',
        })
      )
        .then((clientInfo) => {
          if (clientInfo === undefined) {
            throw new Error('login failed');
          }
          setChangeNext(true);
        })
        .catch((error) => {
          console.log(error);
          dispatch(
            signInByEmailPromise({
              email: user.email,
              password: currentPassword,
              role: 'STUDENT',
            })
          )
            .then((clientInfo) => {
              if (clientInfo === undefined) {
                throw new Error('login failed');
              }
              setChangeNext(true);
            })
            .catch((error) => {
              dispatch(
                signInByEmailPromise({
                  email: user.email,
                  password: currentPassword,
                  role: 'PARENT',
                })
              )
                .then((clientInfo) => {
                  if (clientInfo === undefined) {
                    throw new Error('login failed');
                  }
                  setChangeNext(true);
                })
                .catch((error) => {
                  console.log('login fail');
                  setChangeNext(false);
                });
            });
        });
  }

  const onChangePWCheck = useCallback((e) => {
    const pwRegex = /^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/;
    if (!pwRegex.test(e.target.value)) {
      setPWDisabled(true);
    } else {
      setPWDisabled(false);
    }
  }, []);

  const sendChangedPassword = () => {
    console.log('user.accountId',user.accountId, newPassword)
    if (!newPassword) {
      alert('비밀번호를 입력해주세요.');
      return;
    }
    if (PWdisabled) {
      alert('비밀번호는 8~16자 문자, 숫자, 특수문자를 사용하세요.');
      return;
    }
    dispatch(
      updateAccountInfoPromise({
        accountId: user.accountId,
        password: newPassword,
      })
    ).then((response) => {
        console.log('[update]', response);
        if (response === undefined) {
          throw new Error('login failed');
        }
        alert(`비밀번호가 성공적으로 변경되었습니다!`);
        setChangeNext(false);
        setCurrentPassword('')
        setNewPassword('')
        setConfirmedPw('')
      })
      .catch((error) => {
        console.log('[update]', error);
        alert(`정보 변경에 실패했습니다. 입력하신 정보를 확인해주세요.`);
      });
  };

  return (
    <>
      <WithSubnavigation />
      <Container maxW={'7xl'}>
        <Box
            marginTop={{ base: '3', sm: '5' }}
            display="flex"
            flexDirection={'column'}
            alignItems={'center'}
        >
            <Box
                display="flex"
                flexDirection={'column'}
                alignItems={'center'}
                // style={{backgroundColor:'skyblue'}}
            >
              <AccountCircleIcon 
                style={{width: 48, height: 48, color:'#51BBBB', marginBottom: 8}} 
              />
                <Text
                    as="p"
                    color={useColorModeValue('gray.700', 'gray.200')}
                    fontSize="lg"
                    fontWeight={'bold'}
                >
                    {user.userName}
                    <Text fontWeight={300} display={'inline'}>님</Text>
                </Text>
                <Text
                    as="p"
                    color={useColorModeValue('gray.700', 'gray.200')}
                    fontSize="md"
                >
                    {user.email}
                </Text>
            </Box>

            <Box
                display="flex"
                alignItems={'center'}
                style={{marginTop: 20, padding: 10}}
            >
              <Button
                display="flex"
                flexDirection={'column'}
                alignItems={'center'}
                // as={'a'}
                // display={{ base: 'none', md: 'inline-flex' }}
                fontSize={'sm'}
                fontFamily={'Noto Sans KR'}
                fontWeight={300}
                color={'black'}
                bg={'white'}
                borderWidth={1}
                style={{width: 100, height: 100, marginRight: 10}}
                // onClick={() => handleLogout()}
                // _hover={{
                //   bg: 'gray.400',
                //   color: 'white',
                // }}
              >
                <ManageAccountsIcon style={{marginBottom: 8}} />
                회원정보수정
              </Button>
              <Button
                display="flex"
                flexDirection={'column'}
                alignItems={'center'}
                fontSize={'sm'}
                fontFamily={'Noto Sans KR'}
                fontWeight={300}
                color={'black'}
                bg={'white'}
                borderWidth={1}
                style={{width: 100, height: 100}}
                // onClick={() => handleLogout()}
                // _hover={{
                //   bg: 'gray.400',
                //   color: 'white',
                // }}
              >
                <LockIcon style={{marginBottom: 8}} />
                비밀번호변경
              </Button>
            </Box>

            {/* <Box
                display="flex"
                alignItems={'center'}
                marginTop={'1'}
            >
                <Text
                    as="p"
                    color={useColorModeValue('gray.700', 'gray.200')}
                    fontSize="md"
                    marginRight={'4'}
                >
                    이메일
                </Text>
                <Text
                    as="p"
                    color={useColorModeValue('gray.700', 'gray.200')}
                    fontSize="lg"
                    fontWeight={'bold'}
                >
                    {user.email}
                </Text>
            </Box> */}
            
            {/* <Heading as={'h3'} size={'md'} marginTop="10" marginBottom={paragraphSpacing}>
                비밀번호 변경
            </Heading>
            <Text
                as="p"
                color={useColorModeValue('gray.700', 'gray.200')}
                fontSize="sm"
                marginBottom={'2'}
            >
                먼저 현재 비밀번호를 입력해주세요
            </Text>
            <Box display={'flex'} flexDirection={'column'} width={330} alignItems={'flex-end'}>
                <Input 
                    type="password"
                    value={currentPassword}
                    onChange={(event) => setCurrentPassword(event.target.value)} 
                    marginBottom={'2'} 
                />
                <Button onClick={() => onCurrentPWCheck()} width={50} marginBottom={'2'}>다음</Button>
            </Box> */}

            {ChangeNext ? (
              <Box display={'flex'} flexDirection={'column'} width={330} alignItems={'flex-end'}>
                <Input 
                    placeholder="새 비밀번호 입력"
                    type="password"
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                      onChangePWCheck(e);
                    }}
                    marginBottom={'2'} 
                />
                <Text style={{color:'red', fontSize: 12}}>
                  {PWdisabled
                      ? '비밀번호는 8~16자 문자, 숫자, 특수문자를 사용하세요.'
                      : null}
                </Text>
                <Input 
                    placeholder="새 비밀번호 재입력"
                    type="password"
                    value={confirmPw}
                    onChange={(e) => setConfirmedPw(e.target.value)}
                    marginBottom={'2'} 
                />
                <Text style={{color:'red', fontSize: 12}}>
                  {confirmPw && newPassword !== confirmPw
                      ? '비밀번호가 일치하지 않습니다.'
                      : null}
                </Text>
                <Button 
                  width={160} 
                  isDisabled={newPassword !== confirmPw} //왜비활성화안먹히지
                  onClick={() => sendChangedPassword()}
                >비밀번호 변경하기</Button>
              </Box>
            ) : null}
        </Box>
      </Container>
    </>
  );
};

export default MyPage;
