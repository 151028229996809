import { async } from '@firebase/util';
import {
  getBackend,
  getBackendAlt,
  postBackend,
  postBackendAlt,
  patchBackend,
  deleteBackend,
  patchBackendAlt,
} from './Backend';
import axios from 'axios';

/*
 * Description:
 * postBackendAlt is related with new Django API
 * postBackend is related with new Ktor API
 */

//// Account & Client
///// User specific start
// signUp or register
// make anonymous true

// FOCUSPANG DATA VISUALIZATION APIs
// Register an account
export const createTeacherAccount = async (userData) => {
  return await postBackend(`account?role=TEACHER`, userData);
};

export const createStudentAccount = async (userData) => {
  return await postBackend(`account?role=STUDENT`, userData);
};

export const createParentAccount = async (userData) => {
  return await postBackend(`account?role=PARENT`, userData);
};

// SignIn by Email
export const signInByEmail = async (userData) => {
  return await postBackend('signIn/email', userData);
};

// Get an account
export const getAccountInfoByAccountId = async (accountId) => {
  return await getBackend(`account?accountId=${accountId}`);
};

export const getAccountInfoByClientId = async (clientId) => {
  return await getBackend(`account?clientId=${clientId}`);
};

export const getAccountInfoByUserId = async (userId) => {
  return await getBackend(`account?userId=${userId}`);
};

export const getAccountInfoByEmail = async (email) => {
  return await getBackend(`account?email=${email}`);
};

// Update an account
export const updateAccountInfo = async (userData) => {
  return await patchBackend('account', userData);
};

// create client loginhistory
export const loginHistory = async (userData) => {
  return await postBackend('client/loginHistory', userData);
};

// Get a School
export const getSchoolByName = async (name) => {
  return await getBackend(`school?name=${name}`);
};

// Get all school lists with the given parameters
export const getSchoolByOption = async ({
  selectRegion,
  selectLevel,
  schoolIndex,
}) => {
  return await axios.get(
    `https://test.ktor.focuspang.com/v3/school/all?division=${selectRegion}&schoolLevel=${selectLevel}&index=${schoolIndex}`
  );
};

// Get a parentStudentJoint
export const GetParentStudentJoint = async (parentId) => {
  return await getBackend(`parentStudent?parentId=${parentId}`);
};

// Get a nia student data with given parameters information
export const GetNiaStudentInformation = async (clientId) => {
  const { data } = await getBackend(`nia/student?clientId=${clientId}`);
  return data;
};

// Get a nia student data with given parameters 개인성적점수
export const GetNiaStudentData = async (clientId) => {
  return await getBackend(`nia/clientId=${clientId}`); //Token required
};

// Get a nia statistics data with given parameters 평균성적점수
export const GetNiaStatisticsData = async () => {
  return await getBackend('nia/statistics'); //Token required
};

// GET analysisByClientId 충동점수 관련 데이터
export const getAnalysisByClientId = async (clientId) => {
  const { data } = await getBackend(
    `analysis/impulsivity?clientId=${clientId}`
  );
  return data;
};

export const getAnalysisStatistics = async () => {
  const { data } = await getBackend('analysis/impulsivity/statistics');
  return data;
};

/////////////////////////////////////////////////////////////////////
////////////////////////FOCUSPANG legacy/////////////////////////////
/////////////////////////////////////////////////////////////////////
// signIn for
// make anonymous true
export const signIn = async (userData) => {
  return await postBackend('signIn/email', userData, true);
};

export const createTeacherAdmin = async (teacherAccount) => {
  return await postBackend('account?role=TEACHER', teacherAccount);
};

export const createStudentAdmin = async (studentAccount) => {
  return await postBackend('account?role=STUDENT', studentAccount);
};

export const createManagerTeacherAdmin = async (teacherAccount) => {
  return await postBackend('account?role=ADMIN', teacherAccount);
};

export const getAllTeachersAdmin = async (schid) => {
  return await getBackend('account?schoolId=' + schid);
};

export const getAllManagerTeachersAdmin = async (schid) => {
  return await getBackend('account?schoolId=' + schid);
};

export const getAllStudentsAdmin = async (schid) => {
  return await getBackend(`account?schoolId=${schid}`);
};

export const getAllStudentsIndex = async (payload) => {
  const schoolId = payload.schoolId;
  const index = payload.index;
  return await getBackend(
    `account?schoolId=${schoolId}&role=STUDENT&index=${index}`
  );
};

export const findTeachersAdmin = async (search) => {
  return await postBackend('client/find/teacher/admin/', search);
};

export const deleteClientAdmin = async (clientId) => {
  var new_payload = { accountId: clientId.id };
  return await deleteBackend('account/' + clientId.id, new_payload);
};

export const stopClientAdmin = async (clientId) => {
  var profile = clientId;
  return await patchBackend('account', profile);
};

export const reuseClientAdmin = async (clientId) => {
  var profile = clientId;
  return await patchBackend('account', profile);
};

export const updateUserInfoAdmin = async (profile) => {
  return await patchBackend('account', profile);
};

export const updateTeacherInfoAdmin = async (profile) => {
  return await patchBackend('account', profile);
};

export const registerTeacher = async (newUser) => {
  return await postBackend('account/teacher', newUser, true);
};

// membership
export const getMembership = async (clientId) => {
  return await getBackend(`membership?clientId=${clientId}`);
};

export const getMembershipsByGroup = async (groupId) => {
  return await getBackend(`membership?groupId=${groupId}`);
};

export const createMembership = async (membership) => {
  return await postBackend('membership', membership);
};

export const findPasswordClient = async (password_payload) => {
  return await patchBackend('account?role=ADMIN', password_payload);
};

/////// group
export const createGroup = async (group) => {
  return await postBackendAlt('group/create/', group);
};

export const deleteGroup = async (groupId) => {
  return await postBackendAlt('group/delete/', groupId);
};

export const findGroup = async (groupName) => {
  return await getBackendAlt(`group/find/?name=${groupName}`);
};

export const getGroup = async (groupId) => {
  return await getBackendAlt(`group?groupId=${groupId}`);
};

export const getGroupStudents = async (classroomId) => {
  return await getBackendAlt(`group/get/students/?id=${classroomId}`);
};

export const getAllGroup = async () => {
  return await getBackendAlt('group/get/all/');
};

export const getMyGroups = async () => {
  return await getBackendAlt('group/get/list/');
};

export const getGroups = async (groupIds) => {
  return await postBackendAlt('group/get/multiple/', { ids: groupIds });
};

export const getGroupByInviteCode = async (inviteCode) => {
  return await getBackend(`group?inviteCode=${inviteCode}`, true);
};

export const inviteGroupStudents = async (groupId, studentIds) => {
  return await postBackendAlt('group/invite/', {
    id: groupId,
    student_ids: studentIds,
  });
};

export const signUpStudents = async (group, students) => {
  return await postBackendAlt('group/signup/students/', {
    group: group,
    students: students,
  });
};

export const kickGroupStudent = async (groupId, studentIds) => {
  return await postBackendAlt('group/kick/', {
    id: groupId,
    student_ids: studentIds,
  });
};

export const updateGroup = async (group) => {
  return await postBackendAlt('group/update/', group);
};

///// school
export const getSchool = async (schoolId) => {
  return await getBackend(`school?schoolId=${schoolId}`, true);
};
