
export const unpackUser = (accountObject, clientObject, token) => {
  return {
    // Client Fields
    userName: clientObject.userName,
    studentNumber: clientObject.studentNumber,

    schoolId: clientObject.schoolId,
    role: clientObject.role,

    // Account Fields
    accountId: accountObject.accountId,
    clientId: accountObject.clientId,
    
    email: accountObject.email,
    userId: accountObject.userId,
    
    state: accountObject.state,
    phone: accountObject.phone,

    birthdate: accountObject.birthdate,
    gender: accountObject.gender,
    googleId: accountObject.googleId,
    kakaoId: accountObject.kakaoId,
    naverId: accountObject.naverId,

    privacyAccepted: accountObject.privacyAccepted,
    termsAccepted: accountObject.termsAccepted,
    thirdPartyAccepted: accountObject.thirdPartyAccepted,
    controlParentAccepted: accountObject.controlParentAccepted,
    controlStudentAccepted: accountObject.controlStudentAccepted,
    teacherThirdPartyAccepted: accountObject.teacherThirdPartyAccepted,

    createdAt: accountObject.createdAt,
    updatedAt: accountObject.updatedAt,

    // token
    token: token,
  };
};

export const unpackAccount = (accountObject, clientObject) => {
  return {
    accountId: accountObject.accountId,
    clientId: accountObject.clientId,
    
    email: accountObject.email,
    userId: accountObject.userId,
    
    state: accountObject.state,
    phone: accountObject.phone,

    birthdate: accountObject.birthdate,
    gender: accountObject.gender,
    googleId: accountObject.googleId,
    kakaoId: accountObject.kakaoId,
    naverId: accountObject.naverId,

    privacyAccepted: accountObject.privacyAccepted,
    termsAccepted: accountObject.termsAccepted,
    thirdPartyAccepted: accountObject.thirdPartyAccepted,
    controlParentAccepted: accountObject.controlParentAccepted,
    controlStudentAccepted: accountObject.controlStudentAccepted,
    teacherThirdPartyAccepted: accountObject.teacherThirdPartyAccepted,

    createdAt: accountObject.createdAt,
    updatedAt: accountObject.updatedAt,
  };
};

export const unpackClient = (clientObject) => {
  return {
    clientId: clientObject.client.clientId,
    
    userName: clientObject.client.userName,
    studentNumber: clientObject.client.studentNumber,

    schoolId: clientObject.client.schoolId,
    state: clientObject.client.state,
    role: clientObject.client.role,

    createdAt: clientObject.client.createdAt,
    updatedAt: clientObject.client.updatedAt,
  };
};

export const unpackNotification = (notificationData) => {
  let payload = {};
  if (typeof notificationData.payload === "string") {
    const rawPayload = notificationData.payload.replaceAll("'", '"');
    payload = JSON.parse(rawPayload);
  } else {
    payload = notificationData.payload;
  }
  return {
    id: notificationData.id,
    kind: notificationData.kind,
    notificationType: notificationData.notification_type,
    timestamp: notificationData.timestamp,
    titleText: notificationData.title_text,
    bodyText: notificationData.body_text,
    fromUser: notificationData.from_user,
    toUser: notificationData.to_user,
    // payload: notificationData.payload,
    payload: payload,
    state: notificationData.state,
  };
};