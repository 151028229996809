import React from 'react';
import {
  Box,
  Heading,
  Link,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  useColorModeValue,
  Container,
  VStack,
} from '@chakra-ui/react';
import {
  paragraphIndent,
  paragraphLineHeight,
  paragraphSpacing,
} from '@app/assets/theme/typoStyle';

const BlogTags = (props) => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map((tag) => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

export const BlogAuthor = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src="https://100k-faces.glitch.me/random-image"
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

const BlogPageZero = () => {
  return (
    <Container maxW={'7xl'} p="8" mb={'6'}>
      {/* <Heading as="h1" fontFamily={'Noto Sans KR'}>
        닥터 사이먼의 학습과 마음
      </Heading>
      <Divider marginTop="5" /> */}
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}
        >
          {/* <BlogTags tags={['태그 샘플', '태그추가하기']} /> */}
          <Heading marginTop="1">
            <Link textDecoration="none" _hover={{ textDecoration: 'none' }} fontFamily={'Noto Serif KR'}>
              닥터 사이먼의 마음과 공부 분석보고서 001.230508
            </Link>
          </Heading>
          <Text
            as="p"
            marginTop="6"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            // fontFamily={'Noto Sans KR'}
            fontFamily={'Noto Serif KR'}
            // 들여쓰기
            textIndent={paragraphIndent}
            // 줄간격
            lineHeight={paragraphLineHeight}
          >
            십여 년 전, 로버트 드 니로 주연의 에브리바디스 파인(Everybody’s
            fine)이라는 영화가 있었습니다. 평범한 생을 살아온 아버지 로버트 드
            니로는 40여 년을 같이 살았던 아내를 먼저 떠나 보내게 됩니다. 그리고
            어느 연휴 주말 집에 오겠다던 아들, 딸이 오지 못한다고 합니다. 다
            먹고살기 바쁜 거죠. 아버지는 아이들을 만나러 깜짝 여행을 떠납니다.
            그저 여느 부모처럼 자기 자식이 최고가 되길 바라며 노력했던 생각이
            납니다. 그런데 그가 만난 아이들은 자기가 안다고 생각했던 아이도
            아니었고, 그런 삶을 살지도 않고 있었습니다. 드 니로의 명연기와 함께
            지금도 생생하게 남아 있는 장면은 아버지 드 니로가 자식들을 한 명, 한
            명 만나고 헤어지는 장면입니다. 드 니로는 항상 손편지를 건네 주며,
            “너 행복하지?”라는 질문을 던집니다. 그 나이가 되서야 던지는 아버지의
            깨달음입니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            // 문단 간격 벌리기
            marginBottom={paragraphSpacing}
            // 들여쓰기
            textIndent={paragraphIndent}
            // 줄간격
            lineHeight={paragraphLineHeight}
          >
            결국 우리 부모가 원하는 것은 아이들의 행복입니다. 그리고 아이들이
            앞으로 행복하게 살려면 공부라는 게 참 중요하다고 생각합니다. 그런데
            아버지 드 니로처럼 달콤한 부모도 자식이 어떤 마음이었는지, 어떻게
            사는지, 어떻게 공부하는지 잘 모릅니다. 매일 보는 아이들이지만,
            학교에선 어떻게 생활하는지, 친구들은 어떤지, 공부는 어떤지, 어려움은
            없는지 잘 모릅니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            // 문단 간격 벌리기
            marginBottom={paragraphSpacing}
            // 들여쓰기
            textIndent={paragraphIndent}
            // 줄간격
            lineHeight={paragraphLineHeight}
          >
            그래서{' '}
            <Text fontWeight={'bold'} display={'inline'}>
              닥터 사이먼은 지금까지 알지 못했던 우리 아이들의 이야기를 데이터로
              가감없이 드러내 놓을 것입니다.
            </Text>{' '}
            무책임하게 낭만적으로 용기를 북돋는 이야기를 하려는 것이 아닙니다.
            아이들이나 부모조차 알지 못하던 성적의 뒷이야기를 섬세한 센서
            데이터와 첨단 과학기술로 분석해서 아이의 마음과 공부를 치밀하고,
            객관적으로 이해할 수 있도록 돕고자 합니다. 그래서 드 니로 처럼,
            아이들이 성장한 후에나 뒷목잡는 일이 없도록 말입니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            // 문단 간격 벌리기
            marginBottom={paragraphSpacing}
            // 들여쓰기
            textIndent={paragraphIndent}
            // 줄간격
            lineHeight={paragraphLineHeight}
          >
            닥터 사이먼의 데이터 분석은 심리학, 정신의학, 교육심리, 인지과학과
            첨단 센서 기술 및 인공지능 기술을 종합하여 아이들 마음과 공부에 대해
            객관적이고 깊은 시각을 제공합니다. 이 서비스는 서울대 AI 연구원,
            서울대 교육AI 센터, 서울대병원 정신건강의학과, 하버드 의과대학,
            고려대 심리학과 등과 쓰리알이노베이션이 공동연구하며 개발하고
            있습니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            // 문단 간격 벌리기
            marginBottom={paragraphSpacing}
            // 들여쓰기
            textIndent={paragraphIndent}
            // 줄간격
            lineHeight={paragraphLineHeight}
          >
            한 방울, 한 방울이 모여 바다를 이루듯 정기적이고 지속적인 모니터링을
            하면서 아이에 대해 아이 자신과 학부모 모두 객관적으로 이해하고, 이를
            바탕으로 올바른 노력을 하면 건강한 마음은 물론 좋은 학습태도 그리고
            좋은 성적을 거둘 수 있을 것입니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            // 들여쓰기
            textIndent={paragraphIndent}
            // 줄간격
            lineHeight={paragraphLineHeight}
          >
            다만, 이번 닥터 사이먼의 보고서는 작년 여러분의 자녀가 일회성으로
            참여했던 데이터만을 분석할 수 밖에 없는 한계가 있다는 아쉬운 점을
            밝힙니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            // 문단 간격 벌리기
            marginBottom={paragraphSpacing}
            // 들여쓰기
            textIndent={paragraphIndent}
            // 줄간격
            lineHeight={paragraphLineHeight}
          >
            첫 번째 분석 보고서는 양이 좀 많아서, 두 차례로 나누어 공유드립니다.
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
          >
            <li>
              5월 8일 오늘은 충동적 마음과 공부에 관한 개념을 설명하고 약
              1천명의 중학교 1학년 데이터 분석 결과를 보내드리겠습니다.
            </li>
          </Text>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg"
            fontFamily={'Noto Serif KR'}
            marginBottom="6"
          >
            <li>
              5월 15일 다음 주에는 여러분 자녀에 대한 개인별 분석 결과를
              공유하겠습니다.
            </li>
          </Text>
        </Box>
      </Box>
    </Container>
  );
};

export default BlogPageZero;
