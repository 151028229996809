import { combineReducers } from 'redux';
import user from './user/reducer';
import schools from './school/reducer';
import accounts from './accounts/reducer';
import clients from './clients/reducer';
import groups from './groups/reducer';
import control from './control/reducer';
import membership from './membership/reducer';

const rootReducer = combineReducers({
  user,
  clients,
  groups,
  schools,
  control,
  accounts,
  membership,
});

export default rootReducer;
